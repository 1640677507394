.sr-main-container {
  padding-left: 225px;
  padding-right: 225px;
  margin-right: auto;
  margin-left: auto;
}
.sr-title {
  font-size: 28px;
  font-weight: 500;
  line-height: 24px;
  color: black;
}
.sr-search-box-container {
  width: unset;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 32px;
  border: 2px solid #e6e6e6;
  border-radius: 10px;
  margin-top: 32px;
  margin-bottom: 24px;
}
.sr-search-box-container > div:first-child {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sr-search-box-container > div:first-child > input {
  outline: none;
  border: none;
  background-color: unset;
  color: #737579;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding-right: 16px;
  width: 100%;
}
.sr-search-box-container > div:first-child > input::placeholder {
  color: #98989e;
}
.sr-search-box-container > div:first-child > div {
  margin-right: 32px;
}
.sr-search-box-container > div:last-child {
  border-left: 2px solid #d1d5db;
  padding-left: 32px;
}
.sr-search-box-container > div:last-child > div > button {
  width: 144px !important;
}
.sr-count {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #98989e;
}
.sr-container {
  margin-top: 24px;
  padding: 24px;
}
.sr-card {
  display: flex;
  margin-bottom: 24px;
}
.sr-card > div:first-child > img {
  width: 240px;
  height: 250px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  border: 1px solid #d1d5db;
}
.sr-card > div:first-child > div {
  display: none;
}
.sr-card-details {
  width: 100%;
  margin-left: 16px;
}
.sr-card-details > div:first-child {
  margin-bottom: 10px;
}
.sr-card-details > div:first-child > span {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  color: black;
}
.sr-card-details > div:first-child > div > ul {
  left: -10em !important;
}
.sr-card-details > div:nth-child(2) {
  display: flex;
}
.sr-card-details > div:nth-child(2) > div {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.sr-card-details > div:nth-child(2) > div:first-child {
  margin-right: 8px;
}
.sr-card-details > div:nth-child(2) > div > span {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #98989e;
  margin-top: 6px;
  margin-bottom: 6px;
}
.sr-card-details > div:nth-child(2) > div > span > span {
  color: black;
}
.sr-card-buttons {
  display: flex;
  margin-top: 18px;
}
.sr-card-buttons > div:first-child {
  margin-right: 24px;
}
.sr-card-buttons > div > button {
  width: 145px !important;
}

@media screen and (max-width: 767px) {
  .sr-main-container {
    padding-right: 16px;
    padding-left: 16px;
  }
  .sr-title {
    font-size: 16px;
  }
  .sr-search-box-container {
    padding: 14px 12px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .sr-search-box-container > div:first-child > input {
    font-size: 14px;
    line-height: 18px;
  }
  .sr-search-box-container > div:first-child > div {
    margin-right: 10px;
  }
  .sr-search-box-container > div:last-child {
    border-left: none;
    padding-left: 0px;
  }
  .sr-search-box-container > div:last-child > div > button {
    width: 100px !important;
    height: 35px !important;
  }
  .sr-search-box-container > div:last-child > div > button > div {
    margin-right: 8px !important;
  }
  .sr-count {
    display: none;
  }
  .sr-container {
    margin-top: 24px;
    padding: 0px;
  }
  .sr-card {
    flex-direction: column;
    margin-bottom: 16px;
  }
  .sr-card > div:first-child {
    position: relative;
  }
  .sr-card > div:first-child > img {
    width: 100%;
    height: 152px;
  }
  .sr-card > div:first-child > div {
    width: 24px;
    height: 24px;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 12px;
    right: 12px;
    border-radius: 5px;
  }
  .sr-card > div:first-child > div > div > ul {
    left: -10em !important;
  }
  .sr-card-details {
    margin-left: 0px;
    padding: 12px;
  }
  .sr-card-details > div:first-child {
    margin-bottom: 5px;
  }
  .sr-card-details > div:first-child > span {
    font-size: 16px;
  }
  .sr-card-details > div:first-child > div {
    display: none;
  }
  .sr-card-details > div:nth-child(2) > div > span {
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .sr-card-buttons {
    margin-top: 10px;
  }
  .sr-card-buttons > div > button {
    width: 120px !important;
    height: 35px !important;
  }
}

@media screen and (max-width: 1023px) {
  .sr-card-details > div:nth-child(2) > div:first-child {
    margin-right: 0px;
  }
  .sr-card-details > div:nth-child(2) > div:nth-child(2) {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .sr-main-container {
    padding-right: 60px;
    padding-left: 60px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .sr-main-container {
    padding-right: 140px;
    padding-left: 140px;
  }
}
