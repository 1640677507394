.location-card {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.location-card .header {
  color: #fff;
  padding: 8px;
  min-height: 60px;
}
.location-card .header a {
  color: #fff;
}
.location-image {
  padding: 15px;
  height: 130px;
}
.location-image img {
  width: 100%;
  max-height: 110px;
}
.header_sub_title {
  width: 200px !important;
  font-size: 14px;
  font-family: 'Helvetica';
  font-weight: 700;
}
.location-content {
  position: relative;
  display: block;
  width: 100%;
  padding: 8px;
  border-top: 1px solid #eee;
  height: 110px;
}
.location-vehicle-count {
  font-size: 14px;
  font-weight: bold;
}
.marketplace-vehicles {
  display: block;
  width: 100%;
  float: left;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
}

.grid_header {
  color: #ffffff;
  width: 200px;
  font-size: 14px;
  margin-left: -15px;
  padding-left: 10px;
}

.location-card-footer {
  width: 100%;
  float: left;
  position: relative;
  padding-top: 6px;
  padding-bottom: 3px;
  border-top: 1px solid #f6f6f6;
}

.location-card-container {
  display: block;
  float: left;
  width: 250px;
  min-height: 320px;
  border-bottom: 2px solid rgb(206, 214, 219);
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  margin: 8px;
}

@media screen and (max-width: 585px) {
  .location-card-container {
    margin: 5px auto;
    float: unset;
  }
}