.form-layout-container {
  display: flex;
  padding-top: 32px;
}

/* side bar styles */

.form-template-sidebar {
  width: 100%;
  min-width: max-content;
  justify-content: center;
  /* z-index: 10; */
}
.sidebar-height {
  height: 100%;
}
.sidebar-item {
  display: flex;
  color: #4d4e52;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  height: 48px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  align-items: center;
  padding-left: 32px;
  font-weight: 400;
}
.sidebar-item:hover {
  display: flex;
  color: #4d9bb0;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  height: 48px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  align-items: center;
  padding-left: 32px;
  font-weight: 600;
}
.selected-menu {
  background-color: #4d9bb0;
  color: white;
  font-weight: 600;
}
.selected-menu:hover {
  background-color: #4d9bb0;
  color: white;
  font-weight: 700;
}
.sub-sidebar-item {
  display: flex;
  color: #4d4e52;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  height: 48px;
  text-decoration: none;
  height: 48px;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid #d1d5db;
  font-weight: 400;
}
.sub-sidebar-item:hover {
  display: flex;
  color: #4d4e52;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  height: 48px;
  text-decoration: none;
  height: 48px;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid #d1d5db;
  font-weight: 600;
}
.selected-sub-sidebar-item {
  color: #4d9bb0;
  font-weight: 600;
}
.selected-sub-sidebar-item:hover {
  color: #4d9bb0;
  font-weight: 700;
}
.progress-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dfdfdf;
  padding-top: 10px;
  padding-bottom: 10px;
}
.mobile-sidebar-hamberger {
  display: none;
}
.status-wrapper-sidebar {
  display: flex;
  margin-left: 8px;
  color: #98989e;
}
.progressbar-and-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar-container {
  display: flex;
}
.accordian-container {
  display: none;
}
.hide-form {
  display: block;
}
.sub-sidebar-visibility {
  display: block;
  width: 16.666667%;
}
.form-container.sub-sidebar-form-container {
  width: 83.333333%;
}

/* content styles */
.form-container {
  height: 95.5vh;
  padding: 32px;
  background-color: white;
  margin-left: 32px;
  overflow-y: scroll;
}
.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}
.form-header > div:first-child {
  font-size: 28px;
  font-weight: 500;
  line-height: 24px;
  color: black;
}
.form-header > div:nth-child(2),
.next-with-verify {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #4d9bb0;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #4d9bb0;
}
.form-header > div:nth-child(2) > div,
.next-with-verify > div {
  margin-left: 10px;
}
.form-header.form-sub-section > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #4d9bb0;
  cursor: pointer;
}
.form-header.form-sub-section > div:first-child > div:first-child {
  margin-right: 14px;
}
.form-header > span {
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #4d9bb0;
  margin-right: 24px;
  margin-left: auto;
}
.form-body-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.form-body {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 24px;
  /* width: fit-content; */
}
.form-body-media {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 2fr));
  gap: 24px;
  /* width: fit-content; */
}
.form-body.form-body-vertical {
  display: flex;
  flex-direction: column;
}
.form-body.form-body-sub {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.form-body.form-sub-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}
.form-sub-section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-sub-section-title > div:first-child {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: #000000;
}
.form-sub-section-title > div:nth-child(2) {
  display: none;
}
.form-body-sub-item {
  width: 100% !important;
}
.form-body-sub-item > span {
  color: #4d9bb0;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}
.form-body-sub-item > div {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 24px;
  margin-top: 8px;
}
.form-section-navigation-card {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.03);
  padding: 18px 16px;
  border-radius: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #09101d;
}
.form-sub-section-save-button {
  width: 145px;
}
.form-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 32px;
}
.form-footer > div {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.form-footer > div > button {
  width: 145px !important;
}
.form-checkbox-container {
  display: flex;
  flex-direction: column;
}
.form-checkbox-container > span {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #09101d;
  margin-bottom: 16px;
}
.form-checkbox-container > div {
  display: flex;
  align-items: center;
}
.form-checkbox-label {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #09101d;
  margin-bottom: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.form-checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.form-checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #e3eff3;
  border-radius: 50%;
}
.form-checkbox-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.form-checkbox-label input:checked ~ .form-checkbox-checkmark:after {
  display: block;
}
.form-checkbox-label .form-checkbox-checkmark:after {
  top: 6px;
  left: 6px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #4d9bb0;
}
/* TODO - 2024.11.6 -- 
  Styling for smaller screen @media selectors should be
  placed below larger ones so it takes precedence.
*/
@media screen and (max-width: 767px) {
  .tablet-hamberger-menu {
    display: none;
  }
  .sidebar-height {
    height: 100%;
  }
  .form-container {
    height: auto;
    padding: 24px 16px 32px;
    background-color: white;
    margin-left: 0px;
    overflow-y: auto;
  }
  .form-header.form-sub-section > div:nth-child(2) {
    display: none;
  }
  .form-body-container {
    gap: 16px;
  }
  .form-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .form-body-media {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 2fr));
    gap: 20px;
    /* width: fit-content; */
  }
  .form-sub-section-title > div:nth-child(2) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #4d9bb0;
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: #4d9bb0;
  }
  .form-sub-section-title > div:nth-child(2) > div {
    margin-left: 10px;
  }
  .form-body-sub-item > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
  }
  .form-sub-section-save-button {
    width: 120px;
  }
  .form-footer {
    margin-top: 24px;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .form-container.sub-sidebar-form-container {
    width: 100%;
  }
  .form-layout-container {
    display: block;
    padding-top: 16px;
  }
  .sub-sidebar-visibility {
    display: none;
  }
  .hide-form {
    display: none;
  }
  .accordian-container {
    display: block;
    background-color: white;
  }
  .sidebar-container {
    display: none;
  }
  .mobile-sidebar-hamberger {
    display: flex;
  }
  .status-wrapper-sidebar {
    margin-left: 12px;
  }
  .progressbar-and-button-wrapper {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1023px) {
  .form-container {
    margin-left: 0px;
    overflow-y: auto;
  }
  .form-header {
    margin-bottom: 16px;
  }
  .form-header > div:first-child {
    font-size: 18px;
  }
  .form-body-container {
    gap: 16px;
  }
  .form-body {
    gap: 16px;
  }
  .form-body-sub-item > span {
    font-size: 16px;
  }
  .form-body-sub-item > div {
    gap: 16px;
  }
  .form-section-navigation-card {
    margin-top: 0px;
    padding: 14px 16px;
    font-size: 14px;
  }
  .form-container.sub-sidebar-form-container {
    width: 100%;
  }
  .form-layout-container {
    display: block;
    padding-top: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .tablet-hamberger-menu {
    position: absolute;
    top: 8vh;
    right: 0px;
    background-color: white;
    width: 301px;
    border: 1px solid #ebebeb;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    /* border: 1px solid; */
  }
  .sidebar-height {
    height: unset;
  }
  .sub-sidebar-visibility {
    display: none;
  }
  .hide-form {
    display: block;
  }
  .accordian-container {
    display: none;
    background-color: white;
  }
  .sidebar-container {
    display: none;
  }
  .mobile-sidebar-hamberger {
    display: flex;
  }
  .status-wrapper-sidebar {
    margin-left: 12px;
  }
  .progressbar-and-button-wrapper {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .tablet-hamberger-menu {
    display: none;
  }
  .form-container {
    overflow-y: auto;
  }
}
@media screen and (min-width: 1280px) {
  .tablet-hamberger-menu {
    display: none;
  }
}
