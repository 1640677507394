/*!
 * Copyright 2015-2016, Ten Eleven Ltd.
 * All rights reserved.
 *
 * This source code is licensed under the Apache 2.0 license found in the
 * LICENSE file in the root directory of this source tree.
 *
 * @providesModule Searchkit
 *
 */

 @-webkit-keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  0%,
  to {
    -webkit-transform-origin: 50% 100%;
  }
  to {
    transform-origin: 50% 100%;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  0%,
  to {
    -webkit-transform-origin: 50% 100%;
  }
  to {
    transform-origin: 50% 100%;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  0%,
  to {
    -webkit-transform-origin: 50% 100%;
  }
  to {
    opacity: 0;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  0%,
  to {
    -webkit-transform-origin: 50% 100%;
  }
  to {
    opacity: 0;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@-webkit-keyframes spinning-loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes spinning-loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

 .rc-slider {
  position: relative;
  height: 4px;
  width: 100%;
  border-radius: 6px;
  background-color: #e9e9e9;
}

.rc-slider,
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #abe2fb;
  z-index: 1;
}

.rc-slider-handle {
  position: absolute;
  margin-top: -6px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #3574ff;
  z-index: 2;
  outline: none;
}

.rc-slider-handle:hover {
  border-color: #57c5f7;
  background-color: #0c40ae;
}

.rc-slider-handle-active:active {
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
}

.rc-slider-mark {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  font-size: 12px;
  z-index: 3;
}

.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}

.rc-slider-mark-text-active {
  color: #666;
}

.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
  z-index: 1;
}

.rc-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}

.rc-slider-dot,
.rc-slider-dot:first-child,
.rc-slider-dot:last-child {
  margin-left: -4px;
}

.rc-slider-dot-active {
  border-color: #96dbfa;
}

.rc-slider-disabled {
  background-color: #e9e9e9;
}

.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}

.rc-slider-disabled .rc-slider-dot,
.rc-slider-disabled .rc-slider-handle {
  border-color: #ccc;
  background-color: #fff;
  cursor: not-allowed;
}

.rc-slider-disabled .rc-slider-dot,
.rc-slider-disabled .rc-slider-mark-text {
  cursor: not-allowed !important;
}

.rc-slider-tooltip-zoom-down-appear,
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active,
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active {
  -webkit-animation-name: rcSliderTooltipZoomDownIn;
  animation-name: rcSliderTooltipZoomDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  -webkit-animation-name: rcSliderTooltipZoomDownOut;
  animation-name: rcSliderTooltipZoomDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-appear,
.rc-slider-tooltip-zoom-down-enter {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.rc-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  z-index: 4;
  visibility: visible;
}

.rc-tooltip,
.rc-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-tooltip-hidden {
  display: none;
}

.rc-tooltip-placement-top {
  padding: 4px 0 8px;
}

.rc-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}

.rc-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.rc-tooltip-placement-top .rc-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
}

/* searchkit range filter tooltip */
.rc-slider-tooltip {
  position: absolute;
  left: 0;
  top: 0;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 2000;
  border-radius: 50%;
  backdrop-filter: blur(10px);
  padding: 3px 5px .65rem;
  box-shadow: 1px 2px 10px 5px rgba(133,133,133,0.25);
}

/* searchkit range filter tooltip */
.rc-slider-tooltip-hidden {
  display: none;
}

.sk-spinning-loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  border-right: 1px solid rgba(0, 0, 0, 0.4);
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  border-left: 1px solid #000;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: spinning-loader 1.1s infinite linear;
  animation: spinning-loader 1.1s infinite linear;
}

.sk-spinning-loader,
.sk-spinning-loader:after {
  border-radius: 50%;
  width: 14px; 
  height: 14px; 
}

.sk-search-box {
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.sk-search-box form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  position: relative;
}

.sk-search-box__icon {
  -webkit-box-flex: 0;
  -webkit-flex: 0 20px 20px;
  -ms-flex: 0 20px 20px;
  flex: 0 20px 20px;
  margin: 10px 0 0 10px;
  opacity: 0.3;
}

.sk-search-box__icon:before {
  content: '';
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KCgo8IS0tIFRoZSBpY29uIGNhbiBiZSB1c2VkIGZyZWVseSBpbiBib3RoIHBlcnNvbmFsIGFuZCBjb21tZXJjaWFsIHByb2plY3RzIHdpdGggbm8gYXR0cmlidXRpb24gcmVxdWlyZWQsIGJ1dCBhbHdheXMgYXBwcmVjaWF0ZWQuIApZb3UgbWF5IE5PVCBzdWItbGljZW5zZSwgcmVzZWxsLCByZW50LCByZWRpc3RyaWJ1dGUgb3Igb3RoZXJ3aXNlIHRyYW5zZmVyIHRoZSBpY29uIHdpdGhvdXQgZXhwcmVzcyB3cml0dGVuIHBlcm1pc3Npb24gZnJvbSBpY29ubW9uc3RyLmNvbSAtLT4KCgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgoKPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoKCSB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4KCjxwYXRoIGlkPSJtYWduaWZpZXItMi1pY29uIiBkPSJNNDYwLjM1NSw0MjEuNTlMMzUzLjg0NCwzMTUuMDc4YzIwLjA0MS0yNy41NTMsMzEuODg1LTYxLjQzNywzMS44ODUtOTguMDM3CgoJQzM4NS43MjksMTI0LjkzNCwzMTAuNzkzLDUwLDIxOC42ODYsNTBDMTI2LjU4LDUwLDUxLjY0NSwxMjQuOTM0LDUxLjY0NSwyMTcuMDQxYzAsOTIuMTA2LDc0LjkzNiwxNjcuMDQxLDE2Ny4wNDEsMTY3LjA0MQoKCWMzNC45MTIsMCw2Ny4zNTItMTAuNzczLDk0LjE4NC0yOS4xNThMNDE5Ljk0NSw0NjJMNDYwLjM1NSw0MjEuNTl6IE0xMDAuNjMxLDIxNy4wNDFjMC02NS4wOTYsNTIuOTU5LTExOC4wNTYsMTE4LjA1NS0xMTguMDU2CgoJYzY1LjA5OCwwLDExOC4wNTcsNTIuOTU5LDExOC4wNTcsMTE4LjA1NmMwLDY1LjA5Ni01Mi45NTksMTE4LjA1Ni0xMTguMDU3LDExOC4wNTZDMTUzLjU5LDMzNS4wOTcsMTAwLjYzMSwyODIuMTM3LDEwMC42MzEsMjE3LjA0MQoKCXoiLz4KCjwvc3ZnPgoK)
    no-repeat 0 0;
  background-size: contain;
  height: 20px;
  width: 20px;
  display: block;
}

.sk-search-box input.sk-search-box__text {
  padding: 10px 20px;
  width: 100%;
  -webkit-box-flex: 3;
  -webkit-flex: 3;
  -ms-flex: 3;
  flex: 3;
  font-size: 18px;
  border: none;
  height: initial;
  line-height: initial;
}

.sk-search-box input.sk-search-box__text:focus {
  outline: 0;
}

.sk-search-box__action {
  height: 40px;
  visibility: hidden;
}

.sk-search-box__loader {
  -webkit-box-flex: 0;
  -webkit-flex: 0 20px 20px;
  -ms-flex: 0 20px 20px;
  flex-shrink: 0;
  flex-grow: 30px;
  /* flex-basis: 20px; */
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin: 10px;
}

.sk-search-box__loader.is-hidden {
  display: none;
}

.sk-hits-stats__info {
  position: relative;
  float: left;
  margin-right: 25px;
  font-size: 14px;
  color: #777;
}

.sk-action-button,
.sk-no-hits__step-action,
.sk-range-input__submit {
  border: 1px solid #ccc;
  color: #08c;
  border-radius: 3px;
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 12px;
  outline: none;
}

.sk-action-button:hover,
.sk-no-hits__step-action:hover,
.sk-range-input__submit:hover {
  text-decoration: underline;
}

.sk-action-button:active,
.sk-no-hits__step-action:active,
.sk-range-input__submit:active {
  color: #000;
}

.sk-no-hits {
  text-align: center;
  margin-top: 60px;
  color: #444;
  width: 100%;
  clear: both;
}

.sk-no-hits__info {
  font-size: 14px;
}

.sk-no-hits__steps {
  margin-top: 20px;
}

.sk-filter-group-items,
.sk-filter-group-items__list,
.sk-filter-group-items__title,
.sk-filter-group-items__value {
  display: inline-block;
}

.sk-filter-group-items__title {
  font-weight: 700;
}

.sk-filter-group-items__title:after {
  content: ': ';
}

.sk-filter-group-items__value {
  padding-left: 4px;
}

.sk-filter-group-items__value:hover {
  text-decoration: line-through;
  cursor: pointer;
  color: #fa2a00;
}

.sk-filter-group-items__value:after {
  display: inline-block;
  content: ', ';
}

.sk-filter-group-items__value:last-child:after {
  content: '';
}

.sk-filter-group__remove-action {
  display: inline-block;
  cursor: pointer;
  font-weight: 700;
}

.sk-action-bar .sk-filter-groups {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row;
  -ms-flex-flow: row;
  flex-flow: row;
  margin: 0 10px 10px 0;
  color: #08c;
}

.sk-action-bar .sk-filter-group {
  border: none;
  background: #f4f4f4;
  display: inline-block;
  padding: 0 0 0 10px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 3px;
  margin-right: 10px;
}

.sk-action-bar .sk-filter-group-items__title {
  font-weight: 300;
}

.sk-action-bar .sk-filter-group-items__list {
  padding: 10px 0;
}

.sk-action-bar .sk-filter-group__remove-action {
  border-left: 1px solid #ddd;
  padding: 0 15px 0 10px;
  margin-left: 10px;
  font-size: 14px;
}

.sk-action-bar .sk-filter-group__remove-action:hover {
  color: #fa2a00;
}

.sk-layout__filters .sk-filter-groups {
  display: block;
  width: 100%;
  margin-right: 10px;
  color: #666;
}

.sk-layout__filters .sk-filter-group {
  border: none;
  display: block;
  font-size: 14px;
  line-height: 20px;
}

.sk-layout__filters .sk-filter-group__remove-action {
  padding-right: 10px;
}

.sk-item-list.is-disabled {
  display: none;
}

.sk-item-list-option {
  position: relative;
  cursor: pointer;
  padding: 0;
  line-height: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  font-weight: 300;
  
  /* note - when moving this file from index.html to Search.js this became necessary */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.sk-item-list-option input.sk-item-list-option__checkbox {
  margin: 5px 5px 0 1px;
}

.sk-item-list-option.is-active .sk-item-list-option__text,
.sk-item-list-option.is-selected .sk-item-list-option__text {
  font-weight: 700;
  color: #000;
}

.sk-item-list-option__text {
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  font-size: 14px;
  color: #666;
}

.sk-item-list-option__count {
  font-size: 12px;
  text-align: right;
  color: #ccc;
}

.sk-item-list-option.is-histogram {
  z-index: 1;
}

.sk-item-list-option.is-histogram .sk-item-list-option__count {
  padding-right: 4px;
  color: #999;
}

.sk-item-list-option__bar {
  background: #e7e7e7;
  top: 0;
  bottom: 0;
}

.sk-item-list-option__bar,
.sk-item-list-option__bar-container {
  position: absolute;
  display: inline-block;
  right: 0;
  border-radius: 8px;
}

.sk-item-list-option__bar-container {
  top: 3px;
  bottom: 3px;
  width: 40%;
  max-width: 60px;
  z-index: -1;
  background: #f4f4f4;
  overflow: hidden;
}

.sk-select {
  position: relative;
  cursor: pointer;
  max-height: 30px;
  display: inline-block;
}

.sk-select.is-disabled {
  display: none;
}

.sk-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;
  padding: 6px 10px;
  padding-right: 30px;
  border: 1px solid #ccc;
  outline: 0;
  font-weight: 700;
  font-size: 14px;
  border-radius: 3px;
  color: #868686;
  height: initial;
  line-height: initial;
}

.sk-select:after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 10px;
  width: 0;
  height: 0;
  border-top: 6px solid #666;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  pointer-events: none;
}

.sk-tabs {
  width: 100%;
  border-bottom: 1px solid #ccc;
  list-style: none;
  padding: 0;
  margin: 4px 0;
}

.sk-tabs.is-disabled {
  display: none;
}

.sk-tabs-option {
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  color: #08c;
  padding: 10px 15px;
  text-decoration: none;
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-radius: 3px 3px 0 0;
}

.sk-tabs-option.is-active {
  background-color: #fff;
  color: #666;
  border-color: #08c;
  cursor: default;
  border: 1px solid #ccc;
  border-bottom-color: transparent;
}

.sk-tabs-option.is-disabled {
  cursor: initial;
  color: #ccc;
}

.sk-tabs-option__count,
.sk-tabs-option__text {
  display: inline;
}

.sk-tabs-option__count:before {
  content: ' (';
}

.sk-tabs-option__count:after {
  content: ')';
}

.sk-tag-cloud {
  text-align: justify;
  word-wrap: break-word;
  line-height: 16px;
  font-size: 10px;
}

.sk-tag-cloud.is-disabled {
  display: none;
}

.sk-tag-cloud-option {
  display: inline;
  cursor: pointer;
  font-weight: 300;
  color: #666;
  padding: 4px 2px;
  border-right: none;
  text-decoration: none;
}

.sk-tag-cloud-option__count,
.sk-tag-cloud-option__text {
  display: inline;
}

.sk-tag-cloud-option:after,
.sk-tag-cloud-option:before {
  content: ' ';
  display: inline;
}

.sk-tag-cloud-option__count:before {
  content: ' (';
}

.sk-tag-cloud-option__count:after {
  content: ')';
}

.sk-tag-cloud-option.is-active .sk-tag-cloud-option__text {
  color: #000;
  font-weight: 500;
}

.sk-tag-cloud-option.is-disabled {
  cursor: initial;
  color: #ddd;
}

.sk-toggle {
  /*
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    */
  float: left;
  align-content: center;
  text-align: center;
  border-radius: 3px;
  max-height: 30px;
}

.sk-toggle.is-disabled {
  display: none;
}

.sk-toggle-option {
  /*
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    */
  float: left;
  background-color: #fff;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: #868686;
  padding: 4px 12px;
  border-right: none;
  text-decoration: none;
  white-space: nowrap;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.sk-toggle-option:first-child {
  border-radius: 3px 0 0 3px;
  border-left: 1px solid #ccc;
}

.sk-toggle-option:last-child {
  border-radius: 0 3px 3px 0;
}

.sk-toggle-option.is-active {
  background-color: #787878;
  color: #fff;
  border-color: #787878;
}

.sk-toggle-option.is-disabled {
  cursor: initial;
  color: #ddd;
}

.sk-toggle-option__count,
.sk-toggle-option__text {
  display: inline;
}

.sk-toggle-option__count:before {
  content: ' (';
}

.sk-toggle-option__count:after {
  content: ')';
}

.sk-range-input {
  width: 100%;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.sk-range-input__input {
  display: inline-block;
  width: 70px;
  border-radius: 3px;
  border: 1px solid #ccc;
  height: 30px;
  padding: 0 0 0 10px;
  font-size: 12px;
}

.sk-range-input__input.is-error {
  border-color: #fa2a00;
}

.sk-range-input__input:hover {
}

.sk-range-input__submit {
  display: inline-block;
  background: #fff;
  height: 30px;
  padding: 0 10px;
  margin-left: 12px;
  margin-right: 6px;
  font-size: 14px;
}

.sk-range-input__to-label {
  display: inline-block;
  line-height: 30px;
  margin: 0px;
  color: #444;
  font-size: 14px;
}

.sk-range-histogram {
  height: 50px;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.sk-range-histogram__bar {
  background: hsla(100, 5%, 62%, 0.5);
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.sk-range-histogram__bar.is-out-of-bounds {
  background: hsla(100, 5%, 62%, 0.3);
}

.rc-tooltip {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.sk-range-slider {
  padding-top: 16px;
  padding-left: 14px;
  padding-bottom: 16px;
}

.sk-range-slider .rc-slider-handle {
  /* border-color: #ccc; */
}

.sk-range-slider .rc-slider-track {
  background-color: #2e4dff;
}

.sk-range-slider .rc-slider-mark-text-active {
  color: #ccc;
}

.sk-range-slider .rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #ccc;
}

.sk-hierarchical-menu-list.is-disabled {
  display: none;
}

.sk-hierarchical-menu-list__header {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
  color: #000;
}

.sk-hierarchical-menu-list__root {
  margin-left: -10px;
}

.sk-hierarchical-menu-list__hierarchical-options {
  margin-left: 10px;
}

.sk-hierarchical-menu-option {
  position: relative;
  cursor: pointer;
  padding: 0;
  line-height: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-weight: 300;
}

.sk-hierarchical-menu-option input.sk-hierarchical-menu-option__checkbox {
  margin: 5px 5px 0 1px;
}

.sk-hierarchical-menu-option.is-active .sk-hierarchical-menu-option__text,
.sk-hierarchical-menu-option.is-selected .sk-hierarchical-menu-option__text {
  font-weight: 700;
  color: #000;
}

.sk-hierarchical-menu-option__text {
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  font-size: 14px;
  color: #666;
}

.sk-hierarchical-menu-option__count {
  font-size: 12px;
  text-align: right;
  color: #ccc;
}

.sk-hierarchical-refinement-list__header {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
}

.sk-hierarchical-refinement-list__root {
  margin-left: -10px;
}

.sk-hierarchical-refinement-list__hierarchical-options {
  margin-left: 10px;
}

.sk-hierarchical-refinement-list.is-disabled {
  display: none;
}

.sk-hierarchical-refinement-option {
  position: relative;
  cursor: pointer;
  padding: 0;
  line-height: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-weight: 300;
}

.sk-hierarchical-refinement-option
  input.sk-hierarchical-refinement-option__checkbox {
  margin: 5px 5px 0 1px;
}

.sk-hierarchical-refinement-option.is-active
  .sk-hierarchical-refinement-option__text,
.sk-hierarchical-refinement-option.is-selected
  .sk-hierarchical-refinement-option__text {
  font-weight: 700;
  color: #000;
}

.sk-hierarchical-refinement-option__text {
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  font-size: 14px;
  color: #666;
}

.sk-hierarchical-refinement-option__count {
  font-size: 12px;
  text-align: right;
  color: #ccc;
}

.sk-input-filter {
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.sk-input-filter form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  position: relative;
}

.sk-input-filter__icon {
  -webkit-box-flex: 0;
  -webkit-flex: 0 20px 20px;
  -ms-flex: 0 20px 20px;
  flex: 0 20px 20px;
  margin-top: 6px;
  margin-left: 10px;
  opacity: 0.3;
}

.sk-input-filter__icon:before {
  content: '';
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KCgo8IS0tIFRoZSBpY29uIGNhbiBiZSB1c2VkIGZyZWVseSBpbiBib3RoIHBlcnNvbmFsIGFuZCBjb21tZXJjaWFsIHByb2plY3RzIHdpdGggbm8gYXR0cmlidXRpb24gcmVxdWlyZWQsIGJ1dCBhbHdheXMgYXBwcmVjaWF0ZWQuIApZb3UgbWF5IE5PVCBzdWItbGljZW5zZSwgcmVzZWxsLCByZW50LCByZWRpc3RyaWJ1dGUgb3Igb3RoZXJ3aXNlIHRyYW5zZmVyIHRoZSBpY29uIHdpdGhvdXQgZXhwcmVzcyB3cml0dGVuIHBlcm1pc3Npb24gZnJvbSBpY29ubW9uc3RyLmNvbSAtLT4KCgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgoKPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoKCSB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4KCjxwYXRoIGlkPSJtYWduaWZpZXItMi1pY29uIiBkPSJNNDYwLjM1NSw0MjEuNTlMMzUzLjg0NCwzMTUuMDc4YzIwLjA0MS0yNy41NTMsMzEuODg1LTYxLjQzNywzMS44ODUtOTguMDM3CgoJQzM4NS43MjksMTI0LjkzNCwzMTAuNzkzLDUwLDIxOC42ODYsNTBDMTI2LjU4LDUwLDUxLjY0NSwxMjQuOTM0LDUxLjY0NSwyMTcuMDQxYzAsOTIuMTA2LDc0LjkzNiwxNjcuMDQxLDE2Ny4wNDEsMTY3LjA0MQoKCWMzNC45MTIsMCw2Ny4zNTItMTAuNzczLDk0LjE4NC0yOS4xNThMNDE5Ljk0NSw0NjJMNDYwLjM1NSw0MjEuNTl6IE0xMDAuNjMxLDIxNy4wNDFjMC02NS4wOTYsNTIuOTU5LTExOC4wNTYsMTE4LjA1NS0xMTguMDU2CgoJYzY1LjA5OCwwLDExOC4wNTcsNTIuOTU5LDExOC4wNTcsMTE4LjA1NmMwLDY1LjA5Ni01Mi45NTksMTE4LjA1Ni0xMTguMDU3LDExOC4wNTZDMTUzLjU5LDMzNS4wOTcsMTAwLjYzMSwyODIuMTM3LDEwMC42MzEsMjE3LjA0MQoKCXoiLz4KCjwvc3ZnPgoK)
    no-repeat 0 0;
  background-size: contain;
  height: 20px;
  width: 20px;
  display: block;
}

.sk-input-filter__text {
  padding: 7px;
  width: 100%;
  -webkit-box-flex: 3;
  -webkit-flex: 3;
  -ms-flex: 3;
  flex: 3;
  font-size: 14px;
  background: transparent;
  border: none;
  color: #444;
  height: initial;
  line-height: initial;
}

.sk-input-filter__text:focus {
  outline: 0;
}

.sk-input-filter__action {
  height: 30px;
  visibility: hidden;
  display: none;
}

.sk-input-filter__remove:before {
  -webkit-box-flex: 0;
  -webkit-flex: 0 20px 20px;
  -ms-flex: 0 20px 20px;
  flex: 0 20px 20px;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  padding: 5px 10px;
  line-height: 30px;
  color: #444;
  content: 'x';
  cursor: pointer;
}

.sk-input-filter__remove.is-hidden:before {
  display: none;
}

.sk-numeric-refinement-list__header {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
}

.sk-numeric-refinement-list.is-disabled {
  display: none;
}

.sk-numeric-refinement-list-option {
  position: relative;
  cursor: pointer;
  padding: 0;
  line-height: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-weight: 300;
}

.sk-numeric-refinement-list-option
  input.sk-numeric-refinement-list-option__checkbox {
  margin: 5px 5px 0 1px;
}

.sk-numeric-refinement-list-option.is-active
  .sk-numeric-refinement-list-option__text,
.sk-numeric-refinement-list-option.is-selected
  .sk-numeric-refinement-list-option__text {
  font-weight: 700;
  color: #000;
}

.sk-numeric-refinement-list-option__text {
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  font-size: 14px;
  color: #666;
}

.sk-numeric-refinement-list-option__count {
  font-size: 12px;
  text-align: right;
  color: #ccc;
}

.sk-refinement-list__header {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
}

.sk-refinement-list.is-disabled {
  display: none;
}

.sk-refinement-list__view-more-action {
  font-size: 12px;
  color: #08c;
  cursor: pointer;
  margin-top: 5px;
}

.sk-refinement-list-option {
  position: relative;
  cursor: pointer;
  padding: 0;
  line-height: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-weight: 300;
}

.sk-refinement-list-option input.sk-refinement-list-option__checkbox {
  margin: 5px 5px 0 1px;
}

.sk-refinement-list-option.is-active .sk-refinement-list-option__text,
.sk-refinement-list-option.is-selected .sk-refinement-list-option__text {
  font-weight: 700;
  color: #000;
}

.sk-refinement-list-option__text {
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  font-size: 14px;
  color: #666;
}

.sk-refinement-list-option__count {
  font-size: 12px;
  text-align: right;
  color: #ccc;
}

.sk-selected-filters-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-right: 10px;
}

.sk-selected-filters {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-right: 10px;
}

.sk-selected-filters__item {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin: 0 10px 10px 0;
}

.sk-selected-filters-option {
  background: #f4f4f4;
  border: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 0 10px 10px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 3px;
}

.sk-selected-filters-option__name,
.sk-selected-filters-option__remove-action {
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  color: #08c;
}

.sk-selected-filters-option__remove-action {
  padding: 0 10px;
  cursor: pointer;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  border-left: 1px solid #ddd;
  padding: 0 15px 0 10px;
  margin-left: 10px;
}

.sk-selected-filters-option__remove-action:hover {
  color: #fa2a00;
}

.sk-range-filter__header {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
}

.sk-range-filter.is-disabled {
  display: none;
}

.sk-range-filter.is-no-histogram .bar-chart {
  margin-top: 15px;
}

.sk-range-filter-value-labels {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 5px;
}

.sk-range-filter-value-labels__min {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  font-size: 12px;
}

.sk-range-filter-value-labels__max {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  font-size: 12px;
}

.bar-chart {
  height: 50px;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.bar-chart__bar {
  background: hsla(100, 5%, 62%, 0.5);
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.bar-chart__bar.is-out-of-bounds {
  background: hsla(100, 5%, 62%, 0.3);
}

.sk-reset-filters {
  text-align: center;
  border-radius: 3px;
  padding: 10px 20px;
  cursor: pointer;
  color: #08c;
}

.sk-reset-filters.is-disabled {
  cursor: default;
  color: #ddd;
}

.sk-reset-filters__text {
  font-size: 12px;
}

.sk-reset-filters__custom {
  width: 200px;
}

.sk-menu-list__header {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
}

.sk-menu-list-option {
  position: relative;
  cursor: pointer;
  padding: 0;
  line-height: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-weight: 300;
}

.sk-menu-list-option input.sk-menu-list-option__checkbox {
  margin: 5px 5px 0 1px;
}

.sk-menu-list-option.is-active .sk-menu-list-option__text,
.sk-menu-list-option.is-selected .sk-menu-list-option__text {
  font-weight: 700;
  color: #000;
}

.sk-menu-list-option__text {
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  font-size: 14px;
  color: #666;
}

.sk-menu-list-option__count {
  font-size: 12px;
  text-align: right;
  color: #ccc;
}

.sk-tag-filter {
  cursor: pointer;
}

.sk-tag-filter-list,
.sk-tag-filter-list .sk-tag-filter {
  display: inline;
}

.sk-tag-filter-list .sk-tag-filter:after {
  content: ', ';
  cursor: initial;
}

.sk-tag-filter-list .sk-tag-filter:last-child:after {
  content: '';
}

.sk-pagination-navigation .sk-toggle-option:first-child,
.sk-pagination-navigation .sk-toggle-option:last-child {
  /*-webkit-box-flex: 4;
    -webkit-flex: 4;
    -ms-flex: 4;
    flex: 4
    */
}

.sk-table {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #cbcbcb;
}

.sk-table caption {
  color: #000;
  font: italic 85% / 1 arial, sans-serif;
  padding: 1em 0;
  text-align: center;
}

.sk-table td,
.sk-table th {
  border-left: 1px solid #cbcbcb;
  border-width: 0 0 0 1px;
  font-size: inherit;
  margin: 0;
  overflow: visible;
  padding: 0.5em 1em;
}

.sk-table td:first-child,
.sk-table th:first-child {
  border-left-width: 0;
}

.sk-table thead {
  background-color: #e0e0e0;
  color: #000;
  text-align: left;
  vertical-align: bottom;
}

.sk-table td {
  background-color: transparent;
}

.sk-table-odd td,
.sk-table-striped tr:nth-child(2n-1) td {
  background-color: #f2f2f2;
}

.sk-table-bordered td {
  border-bottom: 1px solid #cbcbcb;
}

.sk-table-bordered tbody > tr:last-child > td {
  border-bottom-width: 0;
}

.sk-table-horizontal td,
.sk-table-horizontal th {
  border-width: 0 0 1px;
  border-bottom: 1px solid #cbcbcb;
}

.sk-table-horizontal tbody > tr:last-child > td {
  border-bottom-width: 0;
}

.sk-layout__filters .sk-panel {
  margin-bottom: 16px;
}

.sk-action-bar .sk-panel__header {
  display: none;
}

.sk-panel {
  width: 96%;
}

.sk-panel__header {
  color: #000;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
}

.sk-panel__header.is-collapsable {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 15px;
  position: relative;
}

.sk-panel__header.is-collapsable:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -3px;
  left: -1px;
  width: 0;
  height: 0;
  border-top: 5px solid #666;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

.sk-panel__header.is-collapsable.is-collapsed:after {
  width: 0;
  height: 0;
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  border-left: 5px solid #666;
  margin-top: -5px;
  left: 0;
}

.sk-panel.is-disabled,
.sk-panel__content.is-collapsed {
  display: none;
}

body {
  margin: 0;
}

*,
:after,
:before {
  box-sizing: inherit;
}

.sk-layout {
  box-sizing: border-box;
  background: #f4f4f4;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.sk-layout__top-bar {
  background: #2a4466;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 10px 0;
  z-index: 100;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
}

.sk-layout__body {
  margin: 60px auto 0;
  min-width: 600px;
}

.sk-layout__filters {
  position: relative;
  background: #fafafa;
  padding: 15px;
  margin: 10px 0px 0px 10px;
  float: left;
  width: 240px;
  /*
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 246px;
    -ms-flex: 0 0 246px;
    flex: 0 0 246px;
    */
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
}

.sk-layout__filters .sk-hierarchical-menu-list,
.sk-layout__filters .sk-hierarchical-refinement-list,
.sk-layout__filters .sk-numeric-refinement-list,
.sk-layout__filters .sk-range-filter,
.sk-layout__filters .sk-refinement-list {
  margin-bottom: 20px;
}

.sk-layout__filters .sk-select {
  width: 100%;
}

.sk-layout__filters .sk-range-input {
  margin-top: 14px;
}

.sk-layout__results {
  background: #fafafa;
  margin-top: 10px;
  /*
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .08);
    */
  float: left;
  min-height: 600px;
}

.sk-top-bar__content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
}

.sk-layout__size-l .sk-top-bar__content {
  width: 1280px;
}

.sk-layout__size-l .sk-layout__body {
  max-width: 100%;
}

.sk-layout__size-m .sk-top-bar__content {
  width: 960px;
}

.sk-layout__size-m .sk-layout__body {
  max-width: 960px;
}

.sk-action-bar {
  /*
    -webkit-flex-flow: column wrap;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    margin: 0 15px 15px
    */
  padding: 0px 15px;
}

.sk-action-bar,
.sk-action-bar-row {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 10px;
  /*
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
    */
}

.sk-action-bar-row {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 10px;
}

.sk-action-bar-row:last-child {
  margin: 0;
}

.sk-action-bar-row .sk-select {
  float: right;
}

.sk-action-bar .sk-hits-stats {
  line-height: 30px;
}

.sk-results-list {
  padding: 15px 0;
  float: left;
  width: 100%;
}

.sk-search-box {
  border-radius: 3px;
  position: relative;
  float: left;
  border: 1px solid #e3e3e3;
  margin-top: 4px;
  /*
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
    */
}

.sk-search-box.is-focused {
  /*background: #fff*/
}

.sk-search-box.is-focused .sk-search-box__text {
  color: #444;
}

.sk-search-box.is-focused .sk-search-box__text::-webkit-input-placeholder {
  color: #444;
  font-weight: 300;
}

.sk-search-box form {
  width: 100%;
}

.sk-search-box input.sk-search-box__text {
  box-shadow: none;
  background-color: #fefefe;
  color: #ccc;
  font-size: 16px;
  padding-left: 10px;
}

.sk-search-box input.sk-search-box__text::-webkit-input-placeholder {
  color: #ccc;
  font-weight: 100;
}

.sk-search-box__icon {
  margin: 10px 0 0 15px;
}

.sk-search-box__action {
  display: none;
}

.sk-hits-stats {
  /*
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
    */
}

.sk-range-filter .rc-slider-handle {
  border-color: #2a4466;
}

.sk-range-filter .rc-slider-track {
  background-color: hsla(100, 5%, 62%, 0.5);
}

.sk-reset-filters {
  border: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  margin: 0;
  padding-left: 0;
}

.sk-pagination-navigation {
  float: left;
  margin: 10px 10px 20px 20px;
}

.sk-pagination-navigation.is-numbered {
  float: left;
}

.sk-pagination-select {
  margin: 10px auto;
  text-align: center;
}

.sk-hits-grid,
.sk-hits-list,
.sk-hits-map {
  margin: 0 15px 20px;
  float: left;
  width: 99%;
  padding-right: 10px;
  clear: both;
}

.sk-hits-grid {
  /*
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
    */
  float: left;
}

.sk-hits-grid__no-results {
  text-align: center;
  margin-top: 60px;
}

.sk-hits-grid__item {
  /*
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 250px;
    -ms-flex: 1 1 250px;
    flex: 1 1 250px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px
    */
}

.sk-hits-grid-hit {
  /*
    padding: 10px;
    max-width: 250px;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
    -webkit-box-flex: 190px;
    -webkit-flex: 190px 0 0;
    -ms-flex: 190px 0 0;
    flex: 190px 0 0
    */
  width: 230px;
  height: 535px;
  float: left;
  margin: 1em;
}

.sk-hits-grid-hit em {
  background: #ff0;
}

.sk-hits-grid-hit a {
  text-decoration: none;
  /*
    color: #08c
    */
}

.sk-hits-grid-hit__title {
  margin-top: 10px;
  font-size: 14px;
}

.sk-hits-list {
  /*
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column wrap;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
    */
}

.sk-hits-list em {
  background: #ff0;
}

.sk-hits-list a {
  text-decoration: none;
  /*
    color: #08c
    */
}

.sk-hits-list__no-results {
  text-align: center;
  margin-top: 60px;
}

.sk-hits-list__item,
.sk-hits-map__item {
  margin-bottom: 20px;
  width: 100%;
}

.sk-hits-list-hit,
.sk-hits-list__item,
.sk-hits-map__item {
  /*
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
    */
  float: left;
}

.sk-hits-list-hit__poster {
  width: 200px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 200px;
  -ms-flex: 0 0 200px;
  flex: 0 0 200px;
  padding: 0 20px;
}

.sk-hits-list-hit__poster img {
  max-width: 100%;
  margin: 0 auto;
}

.sk-hits-list-hit__details {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 0;
  -ms-flex: 1 0 0;
  flex: 1 0 0;
  padding: 10px;
}

.sk-hits-list-hit__title {
  line-height: 20px;
  margin: 0 0 5px;
  font-size: 18px;
}

.sk-hits-list-hit__subtitle {
  margin: 0 0 5px;
}

.sk-hits-list-hit__subtitle,
.sk-hits-list-hit__tags {
  line-height: 20px;
  font-size: 14px;
  color: #666;
  font-weight: 300;
}

.sk-hits-list-hit__tags {
  margin: 0;
  list-style: none;
  padding: 0;
  margin-bottom: 10px;
}

.sk-hits-list-hit__tags .sk-tag-filter:hover {
  color: #08c;
}

.sk-hits-list-hit__tags .sk-tag-filter:active {
  color: #000;
}

.sk-hits-list-hit__text {
  line-height: 22px;
  max-width: 800px;
}

.sk-action-bar .sk-select,
.sk-action-bar .sk-toggle {
  margin-left: 15px;
}

.sk-layout__filters .sk-select select {
  width: 100%;
}

.sk-panel__header {
  font-size: 16px;
  color: #787878;
}

/* Custom */

.sk-search-box input.sk-search-box__text {
  box-shadow: none;
  background-color: #fefefe;
  color: #ccc;
  font-size: 16px;
  padding-left: 10px;
}

.sk-search-box input.sk-search-box__text::-webkit-input-placeholder {
  color: #ccc;
  font-weight: 100;
}

.sk-search-box__icon {
  margin: 7px 5px 0 5px;
}

.sk-search-box__action {
  display: none;
}

.sk-search-box input.sk-search-box__text {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border-radius: 0 4px 4px 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.sk-search-box input.sk-search-box__text:focus {
  outline: 0;
}

.sk-panel__content {
  min-height: 30px;
}

.rc-slider-tooltip-inner {
  /* display: inline-block;
  position: relative;
  color: red; */
}

/* used once in search page*/
/* .no-sales-checkbox > div:first-child > div:last-child {
  margin-bottom: -10px !important;
  min-height: 20px;
} */

.sk-hierarchical-menu-list__root
  > .sk-hierarchical-menu-list__hierarchical-options {
  /* background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  max-height: 200px;
  overflow-y: scroll; */
}

.sk-hierarchical-menu-option.sk-hierarchical-menu-list__item {
  border-bottom: 1px solid #f5f5f5;
}

.sk-hierarchical-menu-option__text {
  white-space: pre !important;
  padding-bottom: 3px;
}

/* * * Filter-specifc styles * * */

/* Image Count ('Photo Count')   */
.sk-panel__content .sk-panel.filter--imageCount .sk-panel__header {
  font-size: 14px;
  text-align: center;
}
.sk-panel.filter--imageCount .sk-range-slider {
  padding-top: 12px;
  padding-bottom: 16px;
}

@media screen and (max-width: 1100px) {
  .sk-layout__body {
    margin: 40px auto 0;
    min-width: 650px;
  }
  .sk-hits-list {
    margin: .5rem .75rem 1rem;
  }
  .sk-hits-stats__info { /* 'x results found' */
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .sk-layout__filters {
    box-shadow: none;
    margin: 0;
  }
  
  .sk-pagination-navigation {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .sk-select select {
    font-size: 16px;
  }

  .sk-selected-filters {
    margin-left: 10px;
    justify-content: center;
  }
  /* .sk-selected-filters-container {
    flex-shrink: 0;
  } */
  .sk-reset-filters {
    padding: 5px 10px;
  }
  .sk-reset-filters__custom {
    width: 150px;
  }
  
  .sk-results-list__action-bar {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  
  .sk-action-bar>.sk-action-bar-row {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
  }
  
}
@media screen and (max-width: 650px) {
  .sk-action-bar>.sk-action-bar-row {
    flex-wrap: wrap;
    gap: .5rem 0px;
    justify-content: flex-start;
  }
  .sk-hits-list {
    margin: 10px;
    padding-right: 0px;
    width: auto;
  }
  .sk-layout__body {
    margin: 30px auto 0;
    min-width: 350px;
  }
  .sk-pagination-navigation {
    margin: 0px;
  }
  .sk-reset-filters__custom {
    width: 125px;
  }
  .sk-results-list {
    padding: .5rem 0;
  }
  .sk-search-box input.sk-search-box__text {
    font-size: 16px;
  }
  .sk-select {
    font-size: 16px;
  }
  .sk-layout__filters>div:first-child {
    display: flex;
    max-height: unset;
    justify-content: flex-end;
    /* position: sticky; */
    padding: 5px;
    top: -2px;
    z-index: 1100;
  }
  .sk-layout__filters>div:nth-child(2) {
    /* top: 10%; */
    /* padding-top: 5px; */
    margin-top: 10px;
    z-index: 999;
    top: auto;
  }
  /* .sk-layout__filters>div:nth-child(2) {

  } */
  .sk-layout__filters>div:nth-child(-n + 2) {
    position: sticky;
    width: 100%;
    background-color: #fafafa;
    float: none;
    padding: 5px;
  }
  .sk-layout__filters>div:nth-child(2) .sk-panel .sk-panel__header {
    width: 100%;
    text-align: center;
    padding-bottom: 5px;
  }
  .sk-layout__filters>div:nth-child(1n + 3) {
    margin-top: 10px;
    padding-left: 5px;
  }
  .sk-panel__content .sk-search-box {
    margin-bottom: 10px;
    width: 100%;
    margin: 0 auto;
  }
  .sk-range-input__input {
    font-size: 16px;
  }
  input.geosuggest__input {
    font-size: 16px;
  }
}
@media screen and (max-width: 550px) {
  .sk-hits-grid {
    float: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px;
  }
  .sk-hits-grid-hit {
    width: 80%;
  }
}
@media screen and (max-width: 375px) {
  .sk-layout__body {
    margin: 10px auto 0;
    min-width: 350px;
  }
}