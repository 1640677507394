:root {
  /* :root is a clever way of saying "the outer-most element" */

  --main-Color: #787878; /* the default */
  --selection-curson-disabled: pointer;
  --z-index-dropdown: 100;
  --masthead_min-height: 224px;
  --masthead_min-height_mobile: 184px;
}

body {
  background-color: #f0f3f5;
  color: #787878;
  font-family: Lato, sans-serif, 'Open Sans', 'helvetica', sans-serif;
  min-width: 350px;
}
.padding {
  padding: 15px;
}
.margin-bottom {
  margin-bottom: 10px;
}
.margin-left {
  margin-left: 10px !important;
}
#terms-modal + .modal-backdrop.in {
  opacity: 0.75;
}
.modal-content {
  border-radius: 2px;
}
.center {
  display: block;
  margin: 0px auto;
}
.border {
  border: solid 1px #ccc;
}
.rounded {
  border-radius: 3px;
}
.custom-tooltip {
  background-color: #000;
}
.modal-title {
  color: #535768;
}
.modal-title .fa {
  margin-right: 6px;
}
.green {
  color: #016b19;
}
.affix {
  display: block;
  position: fixed !important;
  width: 100%;
  z-index: 1049;
}
.sticky {
  margin-top: 115px;
}
.btn {
  border-radius: 2px;
}

.btn-primary {
  border: none;
  background-color: #787878;
  /* background-color:var(--main-Color)!important; */
  /*  background-color:#787878;*/
  transition: all 0.5s;
}
/* .btn-primary:hover {
  background-color: #a5adcb;
} */
.alert {
  margin-bottom: 0px;
}
.lrg-search-btn {
  font-size: 22px;
  background-color: #239eb3;
  font-weight: 500;
  transition: all 0.5s;
  padding: 12px 24px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  margin: 20px;
}
.lrg-search-btn:hover {
  background-color: #8394d9;
}
.live-simulcast-btn {
  font-size: 22px;
  background-color: rgba(0, 255, 182, 0.7);
  transition: all 0.5s;
  padding: 12px 24px;
  margin: 20px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  /* display: none; */
}
.live-simulcast-btn:hover {
  background-color: #8394d9;
}

.fa-star.add {
  color: #ccc;
}
.fa-star.remove {
  color: red;
}

/* * * * home * * * */
.dashboard-logo {
  display: inline-block;
  vertical-align: middle; /* vertical alignment of the inline element */
  margin: auto auto;
  max-width: 100%;
  max-height: 200px;
  height: auto;
  width: auto;
}
.helper {
  height: 200px;
  text-align: center; /* align the inline(-block) elements horizontally */
  font: 0/0 a; /* remove the gap between inline(-block) elements */
}

.helper:before {
  /* create a full-height inline block pseudo=element */
  content: ' ';
  display: inline-block;
  vertical-align: middle; /* vertical alignment of the inline element */
  height: 100%;
}
.buyer-dashboard-header {
  display: block;
  width: 100%;
  min-height: 130px;
  background-repeat: no-repeat;
  /* background-attachment: fixed;
  background-size: 100% auto; */
}
.buyer-dashboard-header-overlay {
  width: 100%;
  padding-top: 50px;
  min-height: 130px;
  z-index: 10;
}
.buyer-dashboard-header-stats {
  color: #fff;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.buyer-dashboard-header-stats h1 {
  font-size: 46px;
  font-weight: lighter;
}
.buyer-dashboard-header-stats h3 {
  font-weight: lighter;
}
.buyer-dashboard-header a {
  color: #fff;
}
.buyer-dashboard-header a:hover {
  color: #fefefe;
}
.stat-number {
  display: block;
  font-size: 44px;
  color: #fff;
  width: 100%;
  clear: both;
}
.stat-text {
  font-size: 20px;
  color: #fff;
  width: 100%;
  clear: both;
}
.marketplace-selector {
  display: block;
  max-width: 300px;
  margin: 20px auto;
}
.masthead_left {
  min-height: var(--masthead_min-height);
  flex: 1 1 50%;
  height: 100%;
  padding-left: 20px;
};
.masthead_right {
  min-height: var(--masthead_min-height);
  flex: 1 1 50%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #fff;
  height: 100%;
}
/* HOME page */
.home__masthead-content {
  background-repeat: repeat;
  background-attachment: fixed;
  background-size: auto;
}
.home__dashboard-logo {
  display: inline-block;
  vertical-align: middle;
  height: auto;
  /* width: 'auto; */
  max-height: 200px;
}
.home__stats-section-container {
  display: flex;
  flex: 1 1 0%;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 20px;
}
.home__button-section-container {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.home__stats-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #fff;
  margin: 14px;
}
.home__link-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.5s;
  padding: 12px 24px;
  margin: 20px 60px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  height: 60px;
  width: 300px;
  font-size: 22px;
}
.home__section-header {
  align-items: flex-end;
  display: flex;
}
.home__blank-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 320px; */
  width: 250px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  margin: 8px;
}

@media screen and (max-width: 1250px) {
  .home__masthead-content {
    background-repeat: no-repeat;
    /* fixed background doesn't work on mobile browsers */ 
    background-attachment: unset; 
    /* background-size: auto; */
  }
  .home__stats-section-container {
    padding-bottom: 2px;
  }
  .home__button-section-container {
    padding-bottom: 2px;
  }
}

@media screen and (max-width: 850px) {
  .home__masthead-content {
    background-size: cover;
    background-position: center;
  }
}

@media screen and (max-width: 500px) {
  .home__link-button {
    margin: 10px 0px;
    padding: 6px 8px;
    width: 250px;
  }
  .masthead_left {
    min-height: var(--masthead_min-height_mobile);
    padding-left: 0px;
    text-align: center;
  }
  .home__section-header {
    justify-content: center;
  }
  .masthead_right {
    min-height: var(--masthead_min-height_mobile);
  }
  .home__dashboard-logo {
    /* display: inline-block; */
    /* vertical-align: middle; */
    /* height: auto; */
    /* width: 'auto; */
    max-height: 175px;
    max-width: 100vw;
  }
  .home__stats-container {
    margin: 0px;
  }
  .home__blank-card {
    margin: 8px auto;
  }
}
@media screen and (max-width: 375px) {
  .masthead_left {
    min-height: 155px;
    padding-left: 0px;
    text-align: center;
  };
  .masthead_right {
    min-height: 155px;
    padding-left: 0px;
    max-width: 100vw;
  }
  .home__link-button {
    width: 200px;
  }
  .home__dashboard-logo {
    max-height: 155px;
    max-width: 100vw;
  }
}

.selling-now-section-bg {
  display: block;
  width: 100%;
  min-height: 130px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url('../images/sellingNowBg.jpg');
}
.owl-theme .owl-controls .owl-buttons {
  position: absolute;
  top: -55px;
  right: 20px;
}
.owl-theme .owl-controls .owl-buttons div {
  color: #535768;
  display: inline-block;
  zoom: 1;
  display: inline; /*IE7 life-saver */
  margin: 5px;
  padding: 3px 10px;
  font-size: 12px;
  opacity: 0.8;
  border: 1px solid #ccc;
  border-radius: 3px;
  text-transform: capitalize;
}
ul.main-nav > li > a {
  font-size: 16px;
  font-weight: bold;
}
.user-nav span {
  /* width: 14px; */
}
ul.user-nav > li > a {
  color: #787878;
}
ul.main-nav > li > a {
  color: #787878;
}
.main-nav .dropdown-menu,
.user-nav .dropdown-menu {
  background-color: rgba(255, 255, 255, 0.86);
}
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: transparent;
}
.main-nav .dropdown-menu a,
.user-nav .dropdown-menu a {
  color: #3a3a3a;
  font-weight: bold;
}
.main-nav .dropdown-menu a:hover,
.user-nav .dropdown-menu a:hover {
  background-color: #787878;
  color: rgba(255, 255, 255, 0.86);
}
.logo-btn {
  padding: 3px 6px;
  margin-top: 5px;
  height: 40px;
  background-color: #eee;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  margin-right: 10px;
}
.logo-btn:hover {
  background-color: #cecece;
}

.top-nav {
  /* position: relative; */
  /* display: block; */
  width: 100%;
  height: 56px;
  clear: both;
  /* background-color: #fff; */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  z-index: 1049;
}
.top-nav .nav > li > a:focus,
.top-nav .nav > li > a:hover {
  background-color: transparent;
  color: #787878;
}
/*Top Navigation*/
.top-nav h4 {
  margin-right: 15px;
  margin-top: 19px;
  font-weight: 400;
  color: #fff;
}
.top-nav .logo {
  height: 30px;
  margin-top: 12px;
  margin-right: 10px;
}
.transparentHeader {
  background: linear-gradient(to bottom, #000000, color(#000000 alpha(0%)));
}

ul.main-nav > li > a {
  font-size: 16px;
}
select.subnav-mobile {
  position: relative;
  margin-left: 18px;
  float: left;
  background: transparent;
}
.countdown-fill {
  height: 100%;
  position: absolute;
  animation: widthAmination 1s infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  background-color: red;
  border-radius: 3px;
  opacity: 0.06;
  align-self: flex-start;
}
@keyframes widthAmination {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
.countdown-progress {
  height: 2px;
  display: block;
  position: absolute;
  bottom: 0px;
  width: 100%;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  background-color: #27ae60;
  margin-left: -4px;
  border-radius: 2px;
}
@media (min-width: 768px) {
  .navbar-nav > li > a {
    padding-top: 18px;
    padding-bottom: 18px;
  }
}
@media (min-width: 992px) {
  .desktop-nav {
    display: block;
  }
  .mobile-nav {
    display: none;
  }
  .vdp-widget.desktop {
    display: block;
  }
  .vdp-widget.mobile {
    display: none;
  }
  .subnav-desktop {
    display: block;
  }
  .subnav-mobile {
    display: none;
  }
}

.top-nav-bottom-divider {
  position: relative;
  display: block;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, #a1a1a1 0%, #adadad 100%);
}
.gradient-background {
  background: linear-gradient(90deg, #425059 0%, #646f91 100%);
}
.search-nav,
.nav-container {
  display: block;
  width: 100%;
  height: 52px;
  clear: both;
  background-color: #fbfbfb;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.nav-container {
  float: left;
}
.nav-container h3 {
  margin-top: 12px;
}
/* a {
  color: #787878;
  text-decoration: none !important;
} */

.hd-first > h3 {
  font-weight: bold;
  font-size: 20px;
}
.hd-first > h3,
.hd-first > img,
.hd-first > span {
  float: left;
  padding-left: 22px;
}
.fa-caret-down {
  color: white;
}
.br-list1 {
  border-right: 1px solid black;
  float: left;
}
.navbar-form {
  float: left;
  /* display: inline-block; this is treated as 'block' because of the 'float' property */
}
.top-nav-search input {
  border-radius: 3px;
}
.top-nav-search button {
  border-radius: 3px;
}
.top-nav-search .fa {
  color: #787878;
}
.top-nav-search button:hover {
  background-color: #787878;
}
.top-nav-search button:hover .fa {
  color: #fff;
}
#lg-menu > p {
  width: 11% !important;
  padding-left: 10% !important;
}
#br1-cat,
#br2-cat {
  margin: 8px 0px;
}
#lg1-menu > .col-md-2 {
  width: 100px !important;
}
#br1-cat {
  margin-left: 28px;
}
#br1-cat > a,
#br2-cat > a {
  font-weight: bold;
  font-size: 17px;
}
.fa.fa-caret-up {
  margin: -24px 17%;
  position: absolute;
  font-size: 28px;
  color: #3361a7;
}

a#dropdown-hov:hover .dropdown-nav {
  display: block !important;
}
.status {
  margin-left: 42px;
}

.fa-heartbeat,
.fa-question-circle,
.fa-cog {
  margin-left: 4px;
}
.fa.fa-caret-down {
  color: #3361a7;
}
.menu-item {
  color: #fefefe;
  margin-bottom: 10px;
}

.search-title {
  float: left;
  display: inline-block; /* this is treated as 'block' because of the 'float' property */
  margin-right: 20px;
}
.search-title h4 {
  display: inline-block;
  margin-top: 13px;
  font-size: 20px;
}

/* Top Nav */
.topnav-subnav__wrapper {
  height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(250, 250, 250);
  padding: 15px 30px 15px 20px;
  border-bottom: 1px solid rgb(208, 208, 208);
}

@media screen and (max-width: 650px) {
  .topnav-subnav__wrapper {
    align-items: center;
    flex-wrap: wrap;
    gap: 8px 0px;
    height: auto;
    justify-content: unset !important;
    padding-bottom: 10px;
    width: 100%;
  }
  .topnav-subnav__wrapper>:nth-child(2) {
    /* align-self: flex-start; */
    margin: 0 auto;
  }
}

/*Watchlist Styles*/
.watchlist-nav {
  display: block;
  width: 100%;
  height: 52px;
  clear: both;
  background-color: #fbfbfb;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.watchlist-title {
  float: left;
  display: inline-block;  /* this is treated as 'block' because of the 'float' property */
}
.watchlist-title h4 {
  display: inline-block;
  margin-top: 13px;
  font-size: 20px;
}
.watchlist-title p {
  display: inline-block;
  margin-left: 16px;
}

/*Status Nav Styles*/
.status-nav {
  display: block;
  width: 100%;
  height: 52px;
  clear: both;
  background-color: #fbfbfb;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.status-nav select {
  margin-top: 15px;
  margin-left: 5px;
  color: #fff;
  background-color: #9b9dac;
  border-radius: 3px;
}
.status-title {
  float: left;
  display: inline-block; /* this is treated as 'block' because of the 'float' property */
}
.status-title h4 {
  display: inline-block;
  padding-left: 5px;
  margin-top: 15px;
  font-size: 20px;
}
.navbar-nav.search-links {
  display: block;
  position: absolute;
  left: 42%;
  width: 300px;
}
ul.nav.search-links > li > a {
  padding-bottom: 12px;
}

ul.nav.ssearch-links > li > a:focus,
ul.nav.search-links > li > a:hover {
  background: none;
  border-bottom: 3px solid #848dc3;
}
ul.nav.search-links > li.active > a {
  border-bottom: 3px solid #848dc3;
}

.navbar-nav.status-links {
  margin-left: 30px;
}
ul.nav.status-links > li > a {
  padding-bottom: 12px;
}

ul.nav.status-links > li > a:focus,
ul.nav.status-links > li > a:hover {
  background: none;
  border-bottom: 3px solid #848dc3;
}
ul.nav.status-links > li.active > a {
  border-bottom: 3px solid #848dc3;
}

ul.search-nav-right > li > a {
  color: #8e8e8e;
}
.user-dashboard {
  padding-top: 14px;
}
.user-dashboard h4 {
  border-bottom: 1px solid #efefef;
  padding-bottom: 12px;
  margin-bottom: 14px;
  margin-top: 10px;
  font-size: 20px;
}

@media (max-width: 991px) {
  .desktop-nav {
    display: none;
  }
  .mobile-nav {
    display: block;
  }
  .vdp-widget.desktop {
    display: none;
  }
  .vdp-widget.mobile {
    display: block;
  }
  .subnav-desktop {
    display: none;
  }
  .subnav-mobile {
    display: block;
    font-size: large;
  }
  .status-nav select.subnav-mobile {
    margin-left: 18px;
  }
  .buyer-dashboard-header {
    display: block;
    width: 100%;
    min-height: 130px;
    background-attachment: fixed;
    background-size: auto auto;
    background-repeat: no-repeat;
  }
}

/* Search */
*,
:after,
:before {
  box-sizing: border-box !important;
}

.sk-layout__body {
  margin: 0 auto 0 !important;
}
.search-wrapper {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.search-sidebar,
.search-sidebar-hide,
.search-content,
.search-content-hide {
  -moz-transition: all 0.3s ease-out;
  /* FF4+ */
  -o-transition: all 0.3s ease-out;
  /* Opera 10.5+ */
  -webkit-transition: all 0.3s ease-out;
  /* Saf3.2+, Chrome */
  -ms-transition: all 0.3s ease-out;
  /* IE10 */
  transition: all 0.3s ease-out;
}
.search-sidebar {
  position: relative;
  width: 250px;
  float: left;
  overflow: hidden;
}
.search-sidebar-hide {
  position: relative;
  width: 0;
  float: left;
  overflow: hidden;
  margin-bottom: 0;
}
.search-content {
  margin: 0 0 0 260px;
}
.search-content-hide {
  margin: 0 0 0 0;
}
.srp-slider__custom:hover {
  cursor: pointer;
}

/* Search UI - Tablet */
@media screen and (max-width: 1100px) {
  .search-wrapper {
    --sidebar-width-px: 240px;
  }
  .search-content {
    margin: 0px;
  }
  .search-content-hide {
    margin: 0px
  }
  .search-sidebar {
    box-shadow: 2px 2px 12px 8px rgba(55, 55, 55, 0.25);
    left: 0px;
    position: fixed;
    z-index: 999;
    width: var(--sidebar-width-px);
    overflow-y: auto;
    max-height: 80vh;
  }
  .search-sidebar-hide {
    z-index: 999;
    position: fixed;
  }
  .sk-layout__filters {
    -moz-transition: all 0.3s ease-out;
    /* FF4+ */
    -o-transition: all 0.3s ease-out;
    /* Opera 10.5+ */
    -webkit-transition: all 0.3s ease-out;
    /* Saf3.2+, Chrome */
    -ms-transition: all 0.3s ease-out;
    /* IE10 */
    transition: all 0.3s ease-out;
  }
}
/* Search UI - Phone */
@media screen and (max-width: 650px) {
  .search-sidebar { 
    width: 80%;
    margin: 0 auto;
    left: 10%;
    max-height: 70vh;
  }
  .search-sidebar-hide {
    left: 5%;
    max-height: 70vh;
  }
  .search-sidebar > .sk-layout__filters {
    width: 100%;
    padding: 5px 10px;
  }
}

.event-title {
  padding: 0px 15px;
  margin-bottom: 10px;
  border-bottom: 1px solid #f5f5f5;
  float: left;
  width: 100%;
}
.event-title h3 {
  margin-top: 0px;
}
.embedded-search {
  margin-right: 10px;
  float: left;
}
.embedded-search .sk-search-box input.sk-search-box__text {
  font-size: 14px;
  padding: 5px 10px;
  height: 30px;
}
.embedded-search .sk-search-box__icon {
  margin-left: 10px;
  margin-top: 5px;
}
.embedded-search .sk-search-box {
  margin-top: 0px;
}
/*Vehicle Details Page*/
/* 2024.09 - TODO -- Most of these 'vdp-*' classes are unused */
.bulk-carousel-container .slick-prev:before,
.bulk-carousel-container .slick-next:before {
  color: rgba(6, 6, 6, 0.9);
  font-size: 28px;
}

.vdp-header {
  width: 100%;
  background-color: #f2f2f2;
  z-index: 10;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ececec;
}
.vdp-miles {
  color: #83be97;
  margin-left: 10px;
}

.vdp-widget {
  position: relative;
  display: block;
  width: 100%;
  float: left;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  margin-top: 10px;
}
.vdp-widget-header {
  position: relative;
  width: 100%;
  float: left;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
.vdp-widget-body {
  position: relative;
  width: 100%;
  float: left;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

/* deprecated 
.vdp-widget-footer {
  /* vertical-align: middle;
  padding: 5px;
  margin-bottom: 10px; 
} */
.vdp-widget a {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.vdp-widget a.buy {
  background-color: #83be97;
}
.vdp-main-image {
  border-radius: 3px;
  height: 70px;
  margin-right: 15px;
  margin-top: 8px;
}
.widget-header {
  position: relative;
  width: 100%;
  float: left;
}
.widget-image {
  position: relative;
  width: 90px;
  display: block;
  margin: 0px auto;
}
.vdp-nav {
  display: block;
  width: 100%;
  height: 50px;
  clear: both;
  background-color: #fbfbfb;
  margin-bottom: 10px;
  padding-top: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.vdp-nav h3 {
  margin-top: 10px;
}
.vdp-actions {
  margin-top: 10px;
  list-style: none;
  padding-left: 0px;
  margin-bottom: 10px;
  width: 100%;
}
.vdp-actions li {
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
.vdp-actions > li > a {
  width: 100%;
  position: relative;
  text-align: center;
  float: left;
  font-size: 14px;
}
.main-image {
  max-width: 540px;
}
.image-thumbnails {
  position: relative;
  width: 100%;
  float: left;
  display: block;
  overflow-x: auto;
  margin-top: 15px;
  margin-bottom: 10px;
}
.vdp-thumbnails-wrapper {
  width: 3000px;
  float: left;
  display: block;
}
.vdp-thumbnail {
  position: relative;
  max-width: 100px;
  float: left;
  margin-right: 15px;
  display: inline;
}
.vdp-thumbnail img {
  width: 100%;
}
.vdp-button {
  position: relative;
  float: left;
  border-radius: 3px;
  padding: 6px 10px;
  background-color: #787878;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  transition: all 0.5s;
}
.vdp-button:hover {
  background-color: #8394d9;
  color: #fff;
}
.main-image img {
  width: 100%;
}
.vdp-section {
  width: 100%;
  float: left;
  padding: 20px;
  border-radius: 2px;
  margin: 0px 0px 10px 0px;
}
.vdp-section-header {
  background-color: #f4f4f4;
  padding: 10px 0px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.vdp-section-header h3 {
  margin-top: 5px;
  margin-bottom: 5px;
}

/* deprecated
.vdp-section-body {
  background-color: #fff;
  margin-bottom: 15px;
  padding: 15px 0px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px; 
} */
.cr-iframe {
  width: 100%;
  min-height: 800px;
}
.background-white {
  background-color: #fff;
}
.shadow {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}
.top-margin {
  margin-top: 10px;
}

#vertical-button {
  padding: 12px;
  text-align: center;
  margin: 0px;
  z-index: 15;
  border-radius: 5px 5px 0px 0px;
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transform-origin: bottom right;
  position: fixed;
  top: 200px;
  right: 0px;
}
.vdp-listing-details {
  position: reltive;
  float: left;
  width: 100%;
  clear: both;
  padding: 10px;
}
.listing-detail {
  position: relative;
  float: left;
  display: block;
  width: 46%;
  margin: 2%;
  text-align: center;
}
.listing-detail .value {
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  display: block;
}
.listing-detail .label {
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  display: block;
  color: #787878;
}
.buy-color {
  color: #00b295;
}
.bid-color {
  color: #0a8fb0;
}
.miles-color {
  color: #e67c56;
}
.vehicle-title {
  margin-top: 8px;
}
.center {
  text-align: center;
}
.center-content {
  display: block;
  margin: 0px auto;
}
.show-hide {
  margin-top: -25px;
  color: rgb(107, 125, 171);
  font-weight: bold;
  cursor: pointer;
}
.barcode-wrapper {
  margin-top: 5px;
}
.barcode-wrapper img {
  width: 60px;
}
.pad-left {
  padding: 0 0 0 7px;
}
.st-container {
  margin-top: 10px;
}
.table-image {
  width: 120px;
  max-height: 100px;
  min-height: 100px;
}

.segmented-control {
  width: 200px;
  margin: 3em auto;
  border-radius: 3px;
  overflow: hidden;
}
.segmented-control .label {
  width: 100px;
  float: left;
  background: #ecf0f1;
  color: #2c3e50;
}
.segmented-control .label.selected {
  width: 100px;
  float: left;
  background: #787878;
  color: #ecf0f1;
}
.clearFix {
  clear: both;
}
/* Undefined
.segmented-control {
} */
.segmented-control input {
  display: none;
}
.segmented-control .label {
  text-align: center;
  transition: all 0.2s ease-out;
}
.segmented-control .label label {
  display: block;
  width: 100%;
  height: 100%;
  padding: 1em;
  cursor: pointer;
}
#mapSwitch > .segmented-control {
  width: 150px;
  border-radius: 3px;
  overflow: hidden;
  height: 24px;
}
#mapSwitch > .segmented-control .label {
  width: 75px;
  float: left;
  background: #ecf0f1;
  color: #2c3e50;
  padding: 3px;
}
#mapSwitch > .segmented-control .label.selected {
  width: 75px;
  float: left;
  background: #787878;
  color: #ecf0f1;
  padding: 3px;
}
#mapSwitch > .segmented-control .label label {
  display: block;
  width: 100%;
  height: 100%;
  padding: 3px;
  cursor: pointer;
}

.recall-data {
  text-align: left;
}

/* deprecated *
.autoniq {
  /* width: 100%;
  margin-top: 5px;
  margin-left: 5px;
  max-height: 50px;
  border-radius: 2px;
  cursor: pointer;
}

.autocheck {
  /* width: 100%;
  border-radius: 3px;
  margin-top: 5px;
  max-height: 50px;
  border: solid 1px #ccc;
  cursor: pointer; 
}

.accutrade {
  /* width: 28px;
  margin-bottom: 5px;
  margin-right: 8px;
  max-height: 50px; 
} */

.vehicle-card {
  position: relative;
}
.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}
.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #be3040;
  background: linear-gradient(#f70505 0%, #f70505 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  right: -21px;
}
.ribbon span::before {
  content: '';
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #f70505;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #f70505;
}
.ribbon span::after {
  content: '';
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #f70505;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #f70505;
}
.live-bar {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 45px;
  background-color: #35394c;
  padding: 5px 15px;
  z-index: 999999;
  -webkit-box-shadow: -4px -14px 13px -14px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -4px -14px 13px -14px rgba(0, 0, 0, 0.75);
  box-shadow: -4px -14px 13px -14px rgba(0, 0, 0, 0.75);
}
.live-bar div.live-frame {
  background-color: #f9fafc;
  margin-top: 40px;
}
.live-bar div.live-actions {
  float: right;
}
.live-bar div.live-actions span {
  padding: 2px 4px;
}
.live-bar h4 {
  color: #a1b0d5;
}
.full-screen-section {
  position: relative;
  width: 100%;
  float: left;
  min-height: 100%;
}
.left-arrow {
  position: absolute;
  left: 0px;
  top: 45%;
  height: 55px;
  width: 55px;
  padding: 6px;
  border-radius: 50px;
  background-color: #000;
  opacity: 0.15;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.left-arrow:hover {
  opacity: 0.5;
}
.right-arrow {
  position: absolute;
  top: 45%;
  right: 0px;
  height: 55px;
  width: 55px;
  padding: 6px 12px;
  border-radius: 55px;
  background-color: #000;
  opacity: 0.15;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.right-arrow:hover {
  opacity: 0.5;
}

.full-screen-section .arrow .fa {
  font-size: 45px;
  color: #fefefe;
  text-shadow: 1px 1px #cecece;
}
.vehicle-info table {
  color: #535768;
}
.vehicle-info table h4 {
  font-size: 13px;
  font-weight: 100;
}
.vehicle-info span {
  font-size: 13px;
  font-weight: 900;
  color: #555555;
}
.toggle {
  float: right;
  text-align: center;
  border-radius: 3px;
  max-height: 30px;
  margin-top: 10px;
}
.toggle-option {
  float: left;
  background-color: #fff;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-radius: 0 3px 3px 0;
}
.toggle-option .active {
  background-color: #787878;
  color: #fff;
}
.toggle-option a {
  position: relative;
  display: block;
  float: left;
  width: 100%;
  height: 100%;
  padding: 4px 12px;
}
.toggle-option:first-child {
  border-radius: 3px 0 0 3px;
  border-left: 1px solid #ccc;
}

.filter-selector {
  position: relative;
  cursor: pointer;
  max-height: 30px;
  display: inline-block;
  margin-top: 10px;
  margin-left: 10px;
}

.sort-selector {
  position: relative;
  cursor: pointer;
  max-height: 30px;
  display: inline-block; /* this is treated as 'block' because of the 'float' property */
  margin-top: 10px;
  margin-left: 10px;
  height: 30px;
  /* width: 15em; */
  float: left; 
}

.sort-selector_options {
  color: green;
  font-weight: 800;
  font-size: large;
}

.sort-selector select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;
  padding: 5px 10px;
  padding-right: 30px;

  border: 1px solid #ccc;
  outline: 0;
  font-weight: 700;
  font-size: 13px;
  border-radius: 3px;
  color: #868686;
  height: 30px;
  line-height: initial;
}

.sort-selector:after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 10px;
  width: 0;
  height: 0;
  border-top: 6px solid #666;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  pointer-events: none;
}
.export-buttons {
  margin-top: 10px;
  margin-left: 10px;
}
/* react-image-lightbox */
.ril-close {
  float: none !important;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=')
    no-repeat center !important;
}

/* react-image-gallery */
.image-gallery .fullscreen {
  max-width: 720px;
}
.image-gallery .fullscreen .image-gallery-slide img {
  max-width: 720px;
}

/*Wishlists*/
.wl-sidebar {
  position: absolute;
  left: 0px;
  width: 200px;
  height: 100%;
  border-right: 1px solid #ccc;
  z-index: 100;
}
.wl-main {
  position: relative;
  float: left;
  width: 100%;
  padding-left: 200px;
  z-index: 1;
}
.wl-header {
  width: 100%;
  float: left;
  padding: 0px 15px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  height: 55px;
}
.wl-header h4 {
  margin-top: 18px;
  display: inline-block;
}
.wl-content {
  width: 100%;
  float: left;
  padding: 10px 15px;
  margin-bottom: 25px;
}
.wl-sidebar-list {
  width: 100%;
  float: left;
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.wl-sidebar-list li {
  width: 100%;
}
.wl-sidebar-list li a {
  display: block;
  width: 100%;
  padding: 15px 10px;
  border-bottom: 1px solid #ccc;
  text-decoration: none;
  background-color: #f4f4f4;
}
.wl-add-button {
  display: block;
  float: left;
  width: 100%;
  padding: 17px 10px;
  text-align: center;
  border-bottom: 1px solid #ccc;
  background-color: #fefefe;
}
.wl-form .form-control {
  margin-bottom: 15px;
}
/*Glow Animation*/
.glow {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  margin-right: 8px;
  border-radius: 100%;
  margin-top: 5px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: inset 0px 0px 10px 2px rgba(0, 255, 182, 0.5),
    0px 0px 10px 2px rgba(0, 255, 135, 0.3);
  -webkit-animation: pulse 1s alternate infinite;
  -moz-animation: pulse 1s alternate infinite;
  -moz-animation: pulse 1s alternate infinite;
  animation: pulse 1s alternate infinite;
}
@keyframes pulse {
  0% {
    background: rgba(255, 255, 255, 0.3);
    box-shadow: inset 0px 0px 10px 2px rgba(0, 255, 182, 0.5),
      0px 0px 5px 2px rgba(0, 255, 135, 0.3);
  }
  100% {
    background: rgba(255, 255, 255, 1);
    box-shadow: inset 0px 0px 10px 2px rgba(0, 255, 182, 0.5),
      0px 0px 15px 2px rgba(0, 255, 135, 1);
  }
}
@-webkit-keyframes pulse {
  0% {
    background: rgba(255, 255, 255, 0.3);
    box-shadow: inset 0px 0px 10px 2px rgba(0, 255, 182, 0.5),
      0px 0px 5px 2px rgba(0, 255, 135, 0.3);
  }
  100% {
    background: rgba(255, 255, 255, 1);
    box-shadow: inset 0px 0px 10px 2px rgba(0, 255, 182, 0.5),
      0px 0px 15px 2px rgba(0, 255, 135, 1);
  }
}

@-moz-keyframes pulse {
  0% {
    background: rgba(255, 255, 255, 0.3);
    box-shadow: inset 0px 0px 10px 2px rgba(0, 255, 182, 0.5),
      0px 0px 5px 2px rgba(0, 255, 135, 0.3);
  }
  100% {
    background: rgba(255, 255, 255, 1);
    box-shadow: inset 0px 0px 10px 2px rgba(0, 255, 182, 0.5),
      0px 0px 15px 2px rgba(0, 255, 135, 1);
  }
}

.placesSuggest {
  width: 100%;
  position: relative;
}

.placesSuggest_suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  list-style: none;
  margin: 0;
  padding: 0;
  box-shadow: 0 0.4rem 0.5rem 0.0625rem #dbdbdc;
  z-index: 2;
}

.placesSuggest_suggest {
  padding: 0.3125rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.875rem;
  display: flex;
  align-items: center;
}

.placesSuggest_suggest:hover,
.placesSuggest_suggest-active {
  background: #f5f5f5;
}

.placesSuggest_suggestIcon {
  color: #a7a8aa;
  fill: #a7a8aa;
  margin-right: 5px;
  margin-right: 0.3125rem;
}

.placesSuggest_suggest:hover .placesSuggest_suggestIcon,
.placesSuggest_suggest-active .placesSuggest_suggestIcon {
  color: #40d47e;
  fill: #40d47e;
}

.placesSuggest_suggestLabel {
  font-size: 12px;
  color: #000;
}

.placesSuggest_suggestLabel-matched {
  font-weight: bold;
}

.placesSuggest_suggestAddress {
  padding: 0.3125rem;
  font-size: 10px;
  color: #a7a8aa;
}
/*Lights*/
.lights-container {
  display: block;
  float: left;
  width: 27px;
  padding: 4px 3px;
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 15px;
}
.lights-container .light {
  display: block;
  width: 19px;
  height: 19px;
  border-radius: 15px;
  float: left;
  margin: 2px 3px 2px 0px;
  border: 1px solid #ccc;
}

#table thead tr .reactable-header-sortable {
  border-top: 1px solid #ddd;
  background-image: url(../images/bg.gif);
  background-repeat: no-repeat;
  background-position: center right;
  cursor: pointer;
}

#table thead tr th {
  background-color: #e6eeee;
  border: 1px solid #fff;
  padding: 10px;
}

#table th {
  outline: none;
}

#table thead tr .reactable-header-sort-desc {
  background-image: url(../images/asc.gif);
  background-repeat: no-repeat;
  background-position: center right;
  cursor: pointer;
  background-color: #8dbdd8;
}

#table thead tr .reactable-header-sort-asc {
  background-image: url(../images/desc.gif);
  background-repeat: no-repeat;
  background-position: center right;
  cursor: pointer;
  background-color: #8dbdd8;
}

#table .buttonWidth {
  width: 100px;
}

.wishlist.badge {
  background-color: #999;
  border-radius: 3px;
  margin-right: 6px;
}

.daterangepicker.dropdown-menu {
  z-index: 999999 !important;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.no-broder {
  border: none;
}

.hidden-overflow {
  overflow: hidden;
}

.inline-block-100 {
  display: inline-block;
  width: 100%;
}

.profile-container .profile-details .list-title {
  font-weight: bold;
}

.no-padding {
  padding: 0;
}

.profile-tabs > li.active > a,
.profile-tabs > li.active > a:focus,
.profile-tabs > li.active > a:hover {
  background-color: #787878;
  color: #ffffff;
}

.profile-container {
  background-color: rgb(249, 249, 249);
  margin-top: 20px;
  padding: 20px;
  border-radius: 4px;
}

.profile-container .profile-tab-content {
  padding: 20px 0;
}

.profile-tab__information {
  flex-direction: column;
  align-items: center;
}

.profile-tab__information-form {
  margin-bottom: 40px;
}
.profile-tab__impersonate_td-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  gap: 4px;
  height: max-content;
}
.profile-tab__myAccounts-account {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

/* Profile -- mobile */
@media screen and (max-width: 650px) {
  .profile-container {
    padding: 5px;
    margin: 5px;
  }
  .profile-container .profile-tab-content {
    padding: 15px 0;
  }
  .profile-tab__account-section {
    margin: 10px 0;
  }
  .profile-tab__impersonate_td-actions {
    flex-wrap: wrap;
  }
  .profile-tab__impersonate_td-actions button {
    width: 100%;
  }
  .profile-tab__information-form {
    width: 100%;
    padding: 5px 10px;
    margin-bottom: 0px;
  }
  .profile-tab__myAccounts-account {
    /* padding: 10px; */
    margin: 10px;
    justify-content: space-between;

  }
  
}

/* React Toggle */
.react-toggle {
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19ab27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128d15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19ab27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
  -moz-box-shadow: 0px 0px 3px 2px #0099e0;
  box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
  -moz-box-shadow: 0px 0px 5px 5px #0099e0;
  box-shadow: 0px 0px 5px 5px #0099e0;
}

.tabbable-line > .nav-tabs {
  border: none;
  margin: 0px;
}
.tabbable-line > .nav-tabs > li {
  margin-right: 2px;
}
.tabbable-line > .nav-tabs > li > a {
  border: 0;
  margin-right: 0;
  color: #737373;
  font-weight: bold;
}
.tabbable-line > .nav-tabs > li > a > i {
  color: #a6a6a6;
}
.tabbable-line > .nav-tabs > li.active > a {
  outline: none;
}
.tabbable-line > .nav-tabs > li.open,
.tabbable-line > .nav-tabs > li:hover {
  border-bottom: 2px solid #8f9bad;
}
.tabbable-line > .nav-tabs > li.open > a,
.tabbable-line > .nav-tabs > li:hover > a {
  border: 0;
  background: none !important;
  color: #333333;
}
.tabbable-line > .nav-tabs > li.open > a > i,
.tabbable-line > .nav-tabs > li:hover > a > i {
  color: #a6a6a6;
}
.tabbable-line > .nav-tabs > li.open .dropdown-menu,
.tabbable-line > .nav-tabs > li:hover .dropdown-menu {
  margin-top: 0px;
}
.tabbable-line > .nav-tabs > li.active {
  border-bottom: 2px solid #616e82;
  position: relative;
}
.tabbable-line > .nav-tabs > li.active > a {
  border: 0;
  color: #333333;
}
.tabbable-line > .nav-tabs > li.active > a > i {
  color: #404040;
}
.tabbable-line > .tab-content {
  margin-top: -3px;
  background-color: #fff;
  border: 0;
  border-top: 1px solid #eee;
  padding: 20px;
}

/* Notifications Menu */
.text-uppercase {
  text-transform: uppercase;
}
.notificationsDD .notificationIcon {
  position: relative;
}
.notificationsDD .notificationIcon .label {
  font-family: 'Open Sans', 'helvetica', sans-serif;
  position: absolute;
  top: -8px;
  left: 5px;
  width: auto;
  padding: 3px;
  color: #f3f3f3;
  background-color: #b20000;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}
.notificationsDD .notificationsDDMenu {
  width: 430px;
  border-bottom: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  border-color: #ccc;
  /* height: ; */
}

.panel-primary > .panel-heading {
  border-color: rgb(92, 190, 127) !important;
}

.notification-type-read {
  background-color: rgb(92, 190, 127) !important;
}

.notification-type:hover {
  text-decoration: underline !important;
}
/*Notifications Panel*/
.notificationsDDMenu .notifications-panel {
  font-family: 'Open Sans', 'helvetica', sans-serif;
  border-radius: 0;
  background-color: #d9d3d3;
  border: none;
}
.notificationsDDMenu .notifications-panel .panel-heading {
  position: relative;
  border-radius: 0;
  background: rgb(92, 190, 127);
}
.notificationsDDMenu .notifications-panel .panel-heading .panel-title {
  position: relative;
}
.notificationsDDMenu .notifications-panel .panel-body {
  border-radius: 0;
  padding: 0;
  max-height: 280px;
  overflow-y: scroll;
}
.notificationsDDMenu .notifications-panel .panel-footer {
  border-radius: 0;
  border: none;
}
.notificationsDDMenu .notifications-panel .panel-footer .markAllReadBtn,
.notificationsDDMenu .notifications-panel .panel-footer .viewAllBtn {
  padding: 10px 16px;
  border-radius: 0;
  font-size: 12px;
}
.notificationsDDMenu .notificationItems .notificationItem {
  padding: 10px 5px 10px 5px;
  margin: 1px;
  font-size: 14px;
  cursor: pointer;
}
.notificationsDDMenu
  .notificationItems
  .notificationItem
  .notificationItemSubject {
  font-weight: bold;
  color: #6492bd;
}

.notificationsDDMenu .notificationItems .notificationItem.read {
  background: #f5f3f3;
}
.notificationsDDMenu .notificationItems .notificationItem.read:hover {
  cursor: default;
}
.notificationsDDMenu .notificationItems .notificationItem.unread {
  background: white;
}

.notificationsDDMenu .notificationItems .notificationItem.unread:hover {
  background: #dceaf4;
}
.notifications-list__container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  width: 100%;
}
.notifications-list__item {
  border-bottom: 1px solid rgb(92, 190, 127);
  padding: 0.5em;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
}
.notification-item__status {
  text-align: center;
  flex: 1;
  padding-top: 12px;
}
.notification-item__message {
  flex: 6;
}
.notification-item__message > ul {
  list-style-type: " -      ";
  > li {
    color: rgba(126, 100, 100, 0.7);
    padding: 1px 0;
  }
}
.notification-item__message-content {
  color: var(--main-Color);
}
.notification-item__footer {
  display: flex;
  flex: 2;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.notifyContainer .notifyMessageBtns .continueBtn {
  background-color: #239eb3;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}
.notifyContainer .notifyMessageBtns .continueBtn:hover {
  background-color: #8394d9;
}
#notifications-header {
  text-align: center;
}
.notification-list {
  list-style: none;
  margin-left: 0px;
  float: left;
  padding-left: 0;
}
.notification-list li {
  border-bottom: 1px solid rgb(92, 190, 127);
  float: left;
  padding: 0.5em;
  width: 100%;
}
.notification-item__footer_viewdetails {
  margin-top: 32px;
}
.notification-item__footer_markasread {
  margin-top: 13px
}
@media screen and (max-width: 1100px) {
  .notification-item__footer>* {
    margin: 10px 0;
    vertical-align: middle;
  }
  .notification-item__footer {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    gap: 2px;
  }
}

@media screen and (max-width: 650px) {
  .notification-item__status {
    flex: 0.5;
  }
  .notification-item__message {
    width: 100%;
    margin: 0px;
  }
  .notification-item__message > ul {
    /* list-style-type: square; */
    > li {
      padding: 2px 0;
    }
  }
  .notification-item__message > p {
    padding-left: 0.25em;
  }
  .notification-item__message > h4 {
    padding-bottom: .5em;
    padding-left: 0.25em;
  }
  .notification-item__footer>* {
    margin: 5px 0;
  }
  .notification-item__footer {
    flex: unset;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 0px;
    width: 100%;
    padding: 1em;
  }
  .notifications-list__item {
    flex-flow: row wrap;
    align-items: center;
    padding: 0.75em;
  }
}
@media screen and (max-width: 460px) {
  .notification-item__footer {
    flex-direction: column;
    gap: 5px;
  }
}
/*Tooltip Styles*/
.ias-tooltip {
  position: relative;
}
.ias-tooltip-content {
  font-size: 0.9em;
  line-height: 1.2em;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 8px !important;
  border-radius: 3px;
  display: none;
  position: absolute;
  bottom: 90%;
  left: -60px;
}
.ias-tooltip:hover > span.ias-tooltip-content {
  display: block;
}

/*Private Lot Modal*/
.createPrivateLotModal .alertMessage {
  padding: 5px 0;
  color: #a94442;
}

/*Seller Listed Buyer List Table*/
/* .buyersContainer {
} */
.buyersContainer .buyerListWrapper {
  padding: 10px;
  border: 1px solid #eee;
}
.buyersContainer .buyerListWrapper .buyerName {
  font-size: 12px;
}
.buyersContainer .buyerInviteWrapper {
  padding: 10px;
}
.buyersContainer .buyerInviteWrapper .btn {
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: default;
}
.buyersContainer .buyerInviteWrapper .btn .deleteIcon {
  margin-left: 10px;
  cursor: pointer;
}
/*Seller Listed Search Buyer by Phone*/
.buyersContainer .buyerSearchWrapper {
  padding: 10px;
}
.buyersContainer .buyerListWrapper {
  max-height: 200px;
  overflow-y: scroll;
}
.buyersContainer .buyerListWrapper .buyerListTable {
  margin-bottom: 0;
}
.buyersContainer .buyerListWrapper .buyerListTableRow {
  margin-bottom: 10px;
}

/* TIMELINE
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.timeline ul {
  /*background: #f1f1f1;*/
  padding: 0px 0;
}

.timeline ul li {
  list-style-type: none;
  position: relative;
  width: 6px;
  margin: 0 auto;
  padding-top: 15px;
  background: #fff;

}

.timeline ul li::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: inherit;
}

.timeline ul li div {
  position: relative;
  bottom: 0;
  width: 400px;
  min-height: 180px;
  padding: 15px;
  background: #fff;
  border-radius: 3px;
}

.timeline ul li div::before {
  content: '';
  position: absolute;
  bottom: 7px;
  width: 0;
  height: 0;
  border-style: solid;
}

.timeline ul li:nth-child(odd) div {
  left: 45px;
}

.timeline ul li:nth-child(odd) div::before {
  left: -15px;
  border-width: 8px 16px 8px 0;
  border-color: transparent #fff transparent transparent;
}

.timeline ul li:nth-child(even) div {
  left: -439px;
}

.timeline ul li:nth-child(even) div::before {
  right: -15px;
  border-width: 8px 0 8px 16px;
  border-color: transparent transparent transparent #fff;
}

time {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 8px;
}



/* EFFECTS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.timeline ul li::after {
  transition: background 0.5s ease-in-out;
}

.timeline ul li.in-view::after {
  background: #fff;
}

.timeline ul li div {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.timeline ul li:nth-child(odd) div {
  transform: translate3d(200px, 0, 0);
}

.timeline ul li:nth-child(even) div {
  transform: translate3d(-200px, 0, 0);
}

.timeline ul li.in-view div {
  transform: none;
  visibility: visible;
  opacity: 1;
}

/*Datatable overrides */

table.dataTable {
  border: 1px;
}

table.dataTable.no-footer {
  border-bottom: none !important;
}
.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom-color: #ccc !important;
}
.table.dataTable thead th,
table.dataTable thead td {
  border-bottom-color: #ccc !important;
}
.dataTables_wrapper .dataTables_filter {
  margin-top: 8px;
  margin-left: 10px;
}
/* GENERAL MEDIA QUERIES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

@media screen and (max-width: 900px) {
  .timeline ul li div {
    width: 250px;
  }
  .timeline ul li:nth-child(even) div {
    left: -289px;
    /*250+45-6*/
  }
}

@media screen and (max-width: 600px) {
  .timeline ul li {
    margin-left: 20px;
  }
  .timeline ul li div {
    width: calc(100vw - 91px);
  }
  .timeline ul li:nth-child(even) div {
    left: 45px;
  }
  .timeline ul li:nth-child(even) div::before {
    left: -15px;
    border-width: 8px 16px 8px 0;
    border-color: transparent #fff transparent transparent;
  }
}

.dropdown-link {
  min-width: 120px !important;
}

div.dataTables_wrapper {
  width: 100%;
  margin: 0 auto;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  /* border-top: @caret-width-base dashed; */ 
  /* border-top: @caret-width-base solid ~'\9'; <-- I.E. hack circa 2011 */
  /* border-right: @caret-width-base solid transparent; */
  /* border-left: @caret-width-base solid transparent; */
}

.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle:focus {
  outline: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: var(--z-index-dropdown);
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  /* font-size: @font-size-base; */
  text-align: left;
  /* background-color: @dropdown-bg;
  border: 1px solid @dropdown-fallback-border;
  border: 1px solid @dropdown-border;
  border-radius: @border-radius-base; */
  background-clip: padding-box;

  &.pull-right {
    right: 0;
    left: auto;
  }

  /* .divider {
  } */

  > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    /* line-height: @line-height-base; */
    /* color: @dropdown-link-color; */
    white-space: nowrap;
  }
}

.dropdown-menu > li > a {
  &:hover,
  &:focus {
    text-decoration: none;
    /* color: @dropdown-link-hover-color;
    background-color: @dropdown-link-hover-bg; */
  }
}

.dropdown-menu > .active > a {
  &,
  &:hover,
  &:focus {
    /* color: @dropdown-link-active-color; */
    text-decoration: none;
    outline: 0;
    /* background-color: @dropdown-link-active-bg; */
  }
}

.dropdown-menu > .disabled > a {
  &,
  &:hover,
  /* &:focus {
    color: @dropdown-link-disabled-color;
  } */

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: transparent;
    background-image: none;
    cursor: var(--selection-curson-disabled);
  }
}

.open {
  > .dropdown-menu {
    display: block;
  }

  > a {
    outline: 0;
  }
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}
.dropdown-menu-left {
  left: 0;
  right: auto;
}

.dropdown-header {
  display: block;
  padding: 3px 20px;
  /* font-size: @font-size-small;
  line-height: @line-height-base;
  color: @dropdown-header-color; */
  white-space: nowrap;
}

.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: (var(--z-index-dropdown) - 10);
}

.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}

.dropup,
.navbar-fixed-bottom .dropdown {
  .caret {
    border-top: 0;
    /* border-bottom: @caret-width-base dashed;
    border-bottom: @caret-width-base solid ~'\9'; */
    content: '';
  }
  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px;
  }
}

/* @media (min-width: @grid-float-breakpoint) {
  .navbar-right {
    .dropdown-menu-left {
    }
  }
} */
.dt-buttons {
  margin-top: 25px !important;
}

.dt-buttons a {
  margin-right: 10px !important;
}

.dataTables_filter {
  margin-top: 25px !important;
  padding-right: 50px !important;
}
.dataTables_length {
  margin-top: 25px !important;
  padding-left: 50px !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0px !important;
  margin-left: 0px !important;
  display: inline !important;
  border: 0px !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  border: 0px !important;
}

/* Custom checkbox and radiobox styles */
.ngCheckbox [type='checkbox'] {
  margin: 0;
  position: relative;
  top: 2px;
  margin-right: 4px;
}

.auto-scroll {
  display: block;
  overflow-x: auto;
}
.card-section-country {
  height: 20px;
}

.card-section-country div {
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.margin-activity {
  margin-right: 40px;
}

.event-time {
  display: table-cell;
  white-space: nowrap;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Data Table - Activity, Browse */
.marketplace-datatable_navbar {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.marketplace-datatable_navbar-buttons {
    display: flex;
    width: 40%;
}
.marketplace-datatable_navbar-pagination {
  width: 60%;
  display: flex;
  justify-content: space-between;
}
.marketplace-datatable_navbar-pagination__options {
  display: flex;
  padding: 2px;
}
.marketplace-datatable_navbar-pagination__pages {
  display: flex;
  padding: 2px 10px;
  min-width: 25%;
}
.datatable_navbar-pagination__button {
  font-size: 15px;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  border: none;
}
.datatable_navbar-pagination__button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* Data Table - responsive nav bar */
@media screen and (max-width: 1100px) {
  .marketplace-datatable_navbar {
    display: flex;
    flex-direction: column;
    align-items: unset;
    justify-content: space-between;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .marketplace-datatable_navbar-buttons {
      display: flex;
      justify-content: flex-start;
      margin: 10px 5px 15px;
      width: auto;
  }
  .marketplace-datatable_navbar-pagination {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
  .marketplace-datatable_navbar-pagination__options {
    display: flex;
    align-self: center;
    justify-content: space-between;
    align-items: center;
    margin: 0 2px;
    & .select-wrap {
      font-size: 16px;
    }
  }
  .marketplace-datatable_navbar-pagination__pages {
    display: flex;
    padding: 2px 5px;
    min-width: auto;
  }
  .datatable_navbar-pagination__pagegroup {
    display: flex;
    align-items: center;

  }
  .pagination-bottom .marketplace-datatable_navbar {
    margin-top: 15px;
  }
}

/* runlist table */
/* Note (2024.10.28) - I think most of the below table selectors are deprecated */

.runlistDataTableContainer .react-bs-table {
  border: none !important;
}

.runlistDataTableContainer .react-bs-table .table {
  border: 1px solid #ddd !important;
}

.runlistDataTableContainer
  .react-bs-table
  .react-bs-container-header
  > table
  > thead
  > tr
  > th {
  vertical-align: top;
  position: relative;
}

.runlistDataTableContainer
  .react-bs-table
  .react-bs-container-header
  > table
  > thead
  > tr
  > th
  .order {
  position: absolute;
  top: 6px;
  right: 2px;
}

.runlistDataTableContainer .react-bs-table table td,
.runlistDataTableContainer .react-bs-table table th {
  white-space: initial !important;
  text-overflow: initial !important;
}

/* arbs table */

.buyerArbsDataTableContainer .react-bs-table {
  border: none !important;
}

.buyerArbsDataTableContainer .react-bs-table .table {
  border: 1px solid #ddd !important;
}

.buyerArbsDataTableContainer
  .react-bs-table
  .react-bs-container-header
  > table
  > thead
  > tr
  > th {
  vertical-align: top;
  position: relative;
}

.buyerArbsDataTableContainer
  .react-bs-table
  .react-bs-container-header
  > table
  > thead
  > tr
  > th
  .order {
  position: absolute;
  top: 6px;
  right: 2px;
}

.buyerArbsDataTableContainer .react-bs-table table td,
.buyerArbsDataTableContainer .react-bs-table table th {
  white-space: initial !important;
  text-overflow: initial !important;
}

.buyerArbs-toolbar-container {
  margin-bottom: 10px;
}

.image-gallery-image {
  max-height: 700px;
  object-fit: contain;
}

/* data tables...sort default */
.ReactTable .rt-thead .rt-th {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  background-repeat: no-repeat;
  background-position: 95% 25%;
  background-size: 15px 15px;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sort" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-sort fa-w-10 fa-fw fa-2x"><path fill="rgb(120,120,120)" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z" class=""></path></svg>');
}

/* data tables...sort asc (down arrow) */
.ReactTable .rt-thead .rt-th.-sort-asc {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  background-repeat: no-repeat;
  background-position: 95% 25%;
  background-size: 15px 15px;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sort-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-sort-down fa-w-10 fa-fw fa-2x"><path fill="rgb(120,120,120)" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z" class=""></path></svg>');
}

/* data tables...sort desc (up arrow) */
.ReactTable .rt-thead .rt-th.-sort-desc {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  background-repeat: no-repeat;
  background-position: 95% 25%;
  background-size: 15px 15px;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sort-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-sort-up fa-w-10 fa-fw fa-2x"><path fill="rgb(120,120,120)" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z" class=""></path></svg>');
}

/* data tables...select */
.ReactTable .rt-thead.-filters select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  background-repeat: no-repeat;
  background-position: 95% 0;
  background-size: 15px 15px;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sort-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-sort-down fa-w-10 fa-fw fa-2x"><path fill="rgb(120,120,120)" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z" class=""></path></svg>');
}

/* header row text */
.ReactTable .rt-th > div {
  margin-right: 8px;
}

.profile-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 1px;
  text-overflow: '';
  padding: 2px 30px 2px 10px;
}

.fee-table tr,
.fee-table th,
.fee-table td {
  text-align: center;
  padding: 2px 20px;
  border: 1px solid gray;
  min-width: 150px;
  height: 20px;
}

.contact-table tr,
.contact-table th,
.contact-table td {
  text-align: left;
  padding: 4px 10px;
  border: 1px solid gray;
  min-width: 150px;
  height: 40px;
}

.terms-markup * {
  font-size: 14px
}

/* Events Page */
.events-list_container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 20px;
}

.event-card__header {
  color: rgb(255, 255, 255);
  padding: 8px 12px;
  height: 54px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  font-size: 14px;
  display: flex;
  cursor: pointer;
  background-color: rgb(253, 190, 14);
}
/* -- Responsive styling -- *
  Vehicle Details Page (vdp)
--------------------------- */
.vdp_column-left {
  flex: 2; /* flex-grow: 2; flex-shrink: 1; flex-basis: 0%;  */
  width: 40%;
  margin-right: 5px;
}
.vdp_column-right {
  flex: 3; /* flex-grow: 2; flex-shrink: 1; flex-basis: 0%;  */
  width: 60%;
  margin-top: 0px;
}
.vdp_section {
  border: 1px solid rgb(204, 204, 204);
  padding: 15px;
  margin-bottom: 15px;
  background-color: rgb(255, 255, 255);
  border-radius: 3px;
}
.vdp_section-header {
  font-size: 18px;
  color: black;
  font-weight: bolder;
  padding-top: 10px;
  padding-bottom: 15px;
}
.vdp_section-header__ymmt {
  font-size: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.vdp_section-infocontent-container {
  display: flex;
  justify-content: space-between;
}
.vdp_section-infoheader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 15px;
  margin-bottom: 10px;
  width: 100%;
  z-index: 10;
}
.vdp_section-vehicledetails-wrapper {
  padding-top: 15px;
  min-width: 350px;
}
.vdp_section-history_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  border: none;
  background-color: transparent;
}
#vdp-options.vdp_section {
  display: flex;
  flex-direction: column;
}
.vdp_section-options_container {
  display: flex;
  justify-content: space-between;
  max-height: 700px;
  overflow-y: auto;
}
.vdp_section-options_container>ul {
  flex-basis: 50%;
  padding-left: 24px;
  padding-right: 10px;
}

/* VDP - mobile */
@media screen and (max-width: 650px) {
  .vdp_column-left,
  .vdp_column-right {
    width: 100%;
    flex: unset;
  }
  .vdp_section {
    padding: 5px;
  }
  .vdp_section-header {
    padding-top: 5px;
    padding-bottom: 10px;
  }
  .vdp_section-header__ymmt {
    font-size: clamp(1em, 10vw, 1.35em);
    line-height: normal;
  }
  .vdp_section-infocontent-container {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
  }
  .vdp_section-vehicledetails-buttons {
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 0px 5px;
  }
  .vdp_section-vehicledetails-buttons button {
    max-width: 115px;
    min-width: 90px;
    max-height: 45px;
    padding: 12px 5px !important;
  }
  .vdp_section.vdp_section-history_container {
    padding: 5px;
    margin-bottom: 10px;
    justify-content: center;
  }
  .vdp_section-options_container {
    max-height: 60vh;
    overflow-y: auto;
  }
}

/* -- Responsive styling -- *
  Arbitration
--------------------------- */
.arbitration_modal-terms__wrapper {
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100px;
  justify-content: space-around;
  justify-items: center;
  width: auto;
  margin: .25rem 1rem;
}

table#mp-arb-commenthistory-table {
  /* width: 600px; */
  border-spacing: 0;
  table-layout: fixed;
  width: calc((100%));
}

.mp-arb-comment_history-wrapper>*:nth-child(odd) {
  background: rgba(0,0,0,0.03);
}

.mp-arb-comment_history-wrapper {
  border: 2px inset rgba(133, 133, 133, 0.35);
  max-height: 300px;
  padding: 10px 0px;
  overflow-y: auto;
}

#arb-modal-footer.marketplace-reusable__modal-buttons {
  gap: 20px;
}

#arb-modal-footer.marketplace-reusable__modal-buttons>div{
  width: 50%;
}

#arb-modal-footer.marketplace-reusable__modal-buttons {
  justify-content: flex-end;
}

@media screen and (max-width: 850px) {
  table#mp-arb-commenthistory-table tbody,
  table#mp-arb-commenthistory-table thead {
    & tr th, tr td {
      width: 150px;
    }
  }
}

@media screen and (max-width: 550px) {
  #arb-modal-footer.marketplace-reusable__modal-buttons {
    gap: 5px;
    /* flex-wrap: wrap; */
  }
}
@media screen and (max-width: 450px) {
  #arb-modal-footer.marketplace-reusable__modal-buttons>div {
    /* width: 100%; */
    width: auto;
    margin: 0 auto;
  }
  #arb-modal-footer.marketplace-reusable__modal-buttons{
    align-items: center;
    flex-direction: column-reverse;
    /* flex-wrap: wrap; */
    justify-content: center;
    gap: 10px;
  }
}
/* -- Responsive styling -- *
  Watchlist, Search, No-Sales
--------------------------- */
@media screen and (max-width: 1100px) {
  .events-list_container {
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
    justify-content: flex-start;
    margin-left: min(11%, 15vw, 100px);
    gap: 15px;
  }
  .event-card__header {
    font-size: 16px;
  }
  .events-nav__search_title,
  .watchlist-title {
    width: 100%;
  }
  .search-nav, .watchlist-nav {
    height: auto;
    padding-bottom: 10px;
  }
  .search-nav>div, .watchlist-nav>div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 5px;
  }
  .watchlist-nav .container {
    padding-left: 5px;
    padding-right: 5px;
  }
  /* - Form Controls - *
   Watchlist, Search, No-Sales */
  .search-nav .filter-selector,
  .watchlist-nav .filter-selector {
    max-height: fit-content;
    font-size: 16px;
    margin-left: 0px;
  }
  .search-nav .sort-selector select,
  .watchlist-nav .sort-selector select
   {
    font-size: 16px;
    font-weight: 600;
  }
  .search-nav .form-control,
  .watchlist-nav .form-control {
    font-size: 16px
  }
}

@media screen and (max-width: 650px) {
  .events-list_container {
    justify-content: center;
    margin-left: auto;
  }
}

/* Image Gallery - Fullscreen */
.image-gallery .fullscreen {
  height: 100vh;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-gallery .fullscreen .image-gallery-slide img {
  min-width: 100%;
  max-width: 100%;
  min-width: 800px;
  min-height: 600px;
}
@media screen and (max-width: 1100px) {
  .image-gallery-fullscreen-button .image-gallery-svg, 
  .image-gallery-play-button .image-gallery-svg {
    height: 28px;
    width: 28px;
  }
  .image-gallery .fullscreen .image-gallery-slide img {
    min-width: 650px;
  }
}
@media screen and (max-width: 650px) {
  .image-gallery .fullscreen .image-gallery-slide img {
    min-width: 400px;
  }
}
@media screen and (max-width: 650px) {
  .image-gallery .fullscreen .image-gallery-slide img {
    min-width: 340px;
  }
}

.underline-on-hover:hover {
  text-decoration: underline;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar {
  display: none;
}

.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


.button-action {
  background-color: #6D6D6D;
  color: #FFF;
  border: none;
  border-radius: 5px;
}

.button-cancel {
  background-color: #FFF;
  border: 1px solid #6D6D6D;
  color: #6D6D6D;
  border-radius: 5px;
}

/* Landing Page - Login
-------------------------- */
.marketplace-login__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.marketplace-login__sidebar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 500px;
  height: 100vh;
  overflow-y: auto;
  padding: 35px;
  background-color: rgb(255, 255, 255);
}

.marketplace-login__sidebar-logo {
  display: block;
  max-width: 400px;
  max-height: 200px;
  margin: 0px auto;  
}

.marketplace-login__bg_container {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  flex: 3 1 75%;
  height: 100vh;
}

.marketplace-login__link {
  color: #4183c4;
}

.marketplace-login__apps-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0px 0.5em;
  width: 100%;
  gap: 2.25em;
}

.marketplace-login__apps-wrapper {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  width: 100%;
}

.marketplace-login__apps-qr_wrapper {
  align-content: center;
  display: flex;
  flex-direction: column;
  gap: 0.15em;
  width: 50%;
}
@media screen and (max-width: 550px) {
  .marketplace-login__sidebar-container {
    flex: 1 0 100%;
    padding: 10px 5px;
    background-color: rgb(255, 255, 255);
  }
  
  .marketplace-login__sidebar-logo {
    max-width: unset;
    max-height: 150px;
  }
}

/* IAS-Connect - 'Register / Login'
--------------*/
.mp-auth-connect__wrapper {
  max-width: 100%;
  min-width: auto;
  width: 840px;
}

/* Marketplace Components 
--------------*/
/* Modals */
.marketplace-reusable__modal-content {
  top: 47%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  z-index: 99;
  position: absolute;
  border-radius: 8px;
  border: none;
  box-shadow:
    0 10px 15px 3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  width: 650px;
  min-height: 300px;
  max-height: 800px;
  padding: 10px;
}
#mp-terms-modal.marketplace-reusable__modal-content {
  width: 860px;
}
.marketplace-reusable__modal-body {
  max-height: 620px;
  overflow-y: auto;
}
.marketplace-reusable__modal-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* passed to Modal.overlayClassName */
.marketplace-reusable__modal-overlay {
  background-color: rgba(0, 0, 0, .6);
  inset: 0px;
  position: fixed;
  z-index: 99;
}
.marketplace-reusable__modal-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: auto;
}
.marketplace-reusable__modal-divider {
  border-color: #E5E5E5;
  width: 100%;
  align-self: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.marketplace-reusable__modal-divider__small {
  border-color: #E5E5E5;
  width: 100%;
  align-self: center;
  margin-top: 8px;
  margin-bottom: 8px;
}
.marketplace-reusable__modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.marketplace-reusable__modal-input {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 6px;
  cursor: default;
  color: #555;
}
.marketplace-reusable__modal-footer {
  display: flex;
  justify-content: space-between;
}

.marketplace-reusable__modal-proxy_hard_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-top: 10px;
}
.marketplace-reusable__modal-proxy_hard_shared {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100px;
  border: 1px solid gray;
  text-align: center;
  cursor: pointer;
  font-size: 0.9em;
}

.marketplace-reusable__modal-proxy_button_only {
  height: 40px;
  width: 18%;
  margin: auto;
  margin-bottom: 10px;
  font-weight: 700;
  display: block;
  font-size: 0.9em;
  text-align: center;
  white-space: nowrap;
  background-color: #787878;
  color: #ECF0F1;
  border: none;
  border-radius: 3px;
  outline: none;
}

.marketplace-reusable__modal-terms {
  min-height: 260px;
  width: 800px;
}

.marketplace-reusable__modal-terms_disclaimer {
  padding: 5px;
  margin: 10px 5px;
  max-height: 150px;
  min-height: 120px;
  overflow-y: auto;
  resize: none;
  width: 100%;
}

.marketplace-reusable__modal-textarea {
  text-align: left;
  min-height: 150px;
  max-height: 700px;
  min-width: 100%;
  max-width: 100%;
  border: none;
  background-color: #F5F5F5;
}

.marketplace__modal_transport-input_zip {
  font-size: 12px;
}

.marketplace_modal_transport-providers {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-left: 25px;
  padding-right: 20px;
  opacity: 0;
}

.marketplace_modal_transport-providers.transport-providers_visible {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
  animation-fill-mode: forwards;
	animation-duration: 1.2s;
	-webkit-animation-name: fadeInOpacity;
	-webkit-animation-iteration-count: 1;
	-webkit-animation-timing-function: ease-in;
  -webkit-animation-fill-mode: forwards;
	-webkit-animation-duration: 1.2s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.marketplace_modal_transport-provider {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
  width: 100%;
}

.marketplace__modal_transport-quote_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
  align-self: center;
  height: 100%;
  /* 
  padding-left: 10px;
  padding-top: 10px; */
  /* align-self: center; */
}

.marketplace__modal_transport-container {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  align-content: space-around;
  padding: 0px 5px;
}
.marketplace__modal_transport-acertus_container {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  align-content: space-around;
  padding: 0px 0.15rem;
}

.marketplace-reusable__modal-content .marketplace__modal-loading-text {
  box-shadow: 1px, 2px, 10px, 10px, rgba(144,144,144,0.25);
  font-size: 20px;
  position: fixed;
  right: 40%;
  top: 50%;
  z-index: 2000;
}

#mp-arb-modal.marketplace-reusable__modal-container {
  opacity: 1;
  -moz-transition: opacity 0.3s ease-out;
  /* FF4+ */
  -o-transition: opacity 0.3s ease-out;
  /* Opera 10.5+ */
  -webkit-transition: opacity 0.3s ease-out;
  /* Saf3.2+, Chrome */
  -ms-transition: opacity 0.3s ease-out;
  /* IE10 */
  transition: opacity 0.3s ease-out;
}

#mp-arb-modal.marketplace-reusable__modal-container.modal-container-loading {
  opacity: .5;
  padding: 0;
}

#mp-auth-connect-modal.marketplace-reusable__modal-content {
  width: 840px;
}

.mp-auth-connect__wrapper iframe {
  width: 100%;
  display: flex;
  height: 660px;
}
@media screen and (max-width: 840px) and (not(orientation: landscape)) {
  #mp-auth-connect-modal.marketplace-reusable__modal-content {
    width: 650px;
  }
}
@media screen and (max-width: 1100px) {
  .marketplace-reusable__modal-input,
  .marketplace-reusable__modal-textarea,
  .marketplace__modal_wishlist-input,
  .marketplace__modal_transport-input_zip,
  input#offer-amount.form-control,
  input#bid-amount.form-control {
    font-size: 16px;
  }
  .marketplace-reusable__modal-body {
    padding: 0px 10px 0px 5px;
  }
  .marketplace_modal_transport-providers {
    padding-left: 10px;
    padding-right: 5px;
  }
  .marketplace_modal_transport-provider {
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    align-items: center;
  }

  .marketplace__modal_transport-acertus_container {
    margin: 10px;
  }
  .marketplace__modal_transport-container {
    margin: 5px 15px;
  }
  .marketplace-reusable__modal-terms {
    width: 100%;
  }
  .marketplace__modal_transport-quote_container { 
    align-items: center;
    padding-top: 0px;
  }
  #mp-terms-modal.marketplace-reusable__modal-content {
    width: 650px;
  }
  /* * IAS-Connect * */
  .mp-auth-connect__wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  #mp-auth-connect-modal.marketplace-reusable__modal-content {
    width: 100%;
    top: 45%;
  }
  .marketplace_modal_transport-providers {
    padding-left: 0px;
  }
  .marketplace_modal_transport-provider {
    flex-wrap: wrap;
    padding-left: 0px
  }
}

@media screen and (max-width: 650px) {
  /* Buy Now modal - override bootstrap fontsize */
  #buyer-account.form-control {
    font-size: 16px;
  }
  
  .marketplace-reusable__modal-content {
    min-height: 350px;
    max-height: 90vh;
    width: 100%;
    overflow-y: auto;
    margin-top: 36px;
  }
  #mp-terms-modal.marketplace-reusable__modal-content {
    width: 100%;
    max-height: 85vh;
  }
  
  .marketplace-reusable__modal-container {
    padding: 1.5em .75em;
  }

  #mp-auth-connect-modal.marketplace-reusable__modal-content {
    width: 100%;
    top: 45%;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  #mp-auth-connect-modal.marketplace-reusable__modal-content {
    width: 95%;
    top: 50%;
    max-height: 100%;
  }
}