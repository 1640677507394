.chevron {
  top: 1rem;
  left: 0.8rem;
  transform: rotate(0);
  transform-origin: center;
  transition: 0.2s transform ease;
}
.rotate-chevron {
  transform: rotate(180deg);
  transition: 0.45s transform ease;
}

details {
  overflow: hidden;
}

.accordion-item {
  display: flex;
  color: #98989e;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  height: 54px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  align-items: center;
  padding-left: 16px;
}
.accordion-sub-item {
  display: flex;
  color: #98989e;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  height: 54px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
}
.selected-accordion-sub-item {
  color: #4d9bb0;
  font-weight: 600;
}
.non-selected-accordion-item {
  color: #98989e;
  display: flex;
}
.selected-accordion-item {
  color: #fff;
  display: flex;
}
