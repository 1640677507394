.form-body.conditions-form-body {
  gap: 32px;
}
.splats {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}
.splat-type {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
  width: fit-content;
  border-radius: 8px;
  padding: 5px 20px;
  background-color: #e3eff3;
  cursor: pointer;
}
.splat-type.splat-type-selected {
  color: #ffffff;
  background-color: #4d9bb0;
}
.condition-form-vehicle {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.condition-section-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #000000;
}
.damage-form-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 14px;
  color: #000000;
}
.damage-summary > span {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.damage-summary > div {
  padding: 16px 20px;
  margin-top: 16px;
  border-radius: 4px;
  background-color: #dcebef;
}
.damage-summary > div > div > span {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.damage-summary > div > div > span:first-child {
  font-weight: 500;
}
.damage-form-body-item {
  padding-bottom: 24px;
  /* border-bottom: 1px solid #e6e6e6; */
}
.damage-form-body-item > span {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.damage-form-body-item.damage-photo {
  border-bottom: none;
  padding-bottom: 0;
}
.damage-form-body-item-vertical > div {
  display: flex;
  flex-direction: column;
}
.conditions-loader > div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: black;
  text-align: center;
}
.conditions-loader svg {
  overflow: visible;
}
.review-card-title.needs-review {
  margin-top: 24px;
}
.condition-items-card {
  border: solid 1px #e6e6e6;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
  color: #98989e;
}
.condition-items-card .title {
  color: #000000;
  font-weight: 600;
}
.condition-items-card .details {
  padding: 12px 0;
  border-bottom: 1px solid #e6e6e6;
}
.condition-items-card .options {
  display: flex;
  padding-top: 16px;
}
.condition-items-card > div > div {
  font-size: 12px;
  padding: 6px 12px;
  text-decoration: underline;
  border-right: 1px solid #e6e6e6;
}
.condition-items-card > div > div > span {
  cursor: pointer;
}
.condition-items-card > div > div:first-of-type {
  padding-left: 0;
  padding-right: 12px;
  color: #4d9bb0;
}
.condition-items-card > div > div:last-of-type {
  border: none;
  color: #fc3f47;
}
.quick-review-card {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  margin-top: 24px;
  color: #98989e;
}
.quick-review-card > div {
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid #e6e6e6;
}
.quick-review-card > div:first-of-type {
  padding-top: 0;
}
.quick-review-card .item-label {
  width: 200px;
  color: #000000;
}

@media screen and (max-width: 767px) {
  .splats {
    flex-wrap: nowrap;
    overflow-x: auto;
    scrollbar-width: none; /* Firefox */
  }
  .splats::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  .splat-type {
    display: inline-flex;
  }
  .form-body.conditions-form-body,
  .condition-form-vehicle {
    gap: 16px;
  }
  .condition-section-title {
    font-size: 16px;
  }
  .damage-form-title {
    margin-bottom: 0;
  }
  .damage-summary > div {
    padding: 10px 12px;
  }
  .damage-summary > div > div > span {
    font-size: 14px;
    line-height: 28px;
  }
  .damage-form-body-item.damage-photo {
    padding-bottom: 8px;
  }
  .condition-items-card {
    font-size: 12px;
    padding: 8px;
    margin-top: 8px;
  }
  .condition-items-card .options {
    padding-top: 4px;
  }
  .condition-items-card .details {
    padding: 8px 0;
  }
  .review-card-title.needs-review {
    margin-top: 16px;
  }
  .quick-review-card {
    font-size: 12px;
    padding: 16px;
  }
  .quick-review-card .item-label {
    width: 120px;
    min-width: 120px;
  }
}
