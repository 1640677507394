.tab-button-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-button-group > div {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.tab-button-group > div > button {
  width: 200px !important;
}
.landing-page-text {
  color: #4d9bb0;
  font-size: 36px;
  line-height: 54px;
  font-weight: 700;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 24px;
}
.landing-page-sub-text {
  color: #000000;
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 32px;
}
.search-main-container {
  width: unset;
}
.search-tabs > div {
  margin-right: 0.3rem;
}
.search-tabs > div > button {
  width: 144px !important;
  border-radius: 6px 6px 0px 0px !important;
}
.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  border: 2px solid #e6e6e6;
  border-radius: 10px;
}
.search-container > * {
  flex: 1;
}
.search-container > div:last-child {
  border-left: 2px solid #d1d5db;
  padding-left: 16px;
}
.search-container > div:last-child > label {
  display: flex;
  align-items: center;
  width: max-content;
  color: #737579;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  margin: 0 !important;
  margin-right: 24px !important;
}
.search-container > div:last-child > label > input {
  margin: 0 !important;
  margin-right: 8px !important;
}
.search-container > div:last-child > div > button {
  width: 144px !important;
}
.search-field-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-box {
  outline: none;
  border: none;
  background-color: unset;
  color: #737579;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding-right: 16px;
}
.search-toggles,
.search-btn-mob {
  display: none;
}
.validation-error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(239 68 68);
  margin-top: 8px;
  font-size: 14px;
}
.back-button {
  padding-top: 20px;
  padding-left: 20px;
}
@media screen and (max-width: 767px) {
  .back-button {
    padding-top: 20px;
    padding-left: 0px;
  }
  .tab-button-group > div > button {
    width: 104px !important;
  }
  .landing-page-text {
    font-size: 18px;
    line-height: 27px;
    margin-top: 24px;
    margin-bottom: 8px;
  }
  .landing-page-sub-text {
    font-size: 14px;
    line-height: 18px;
  }
  .search-main-container {
    width: 100%;
  }
  .search-tabs > div {
    margin-right: 0.5rem;
  }
  .search-tabs > div > button {
    width: 80px !important;
    height: 27px !important;
  }
  .search-container {
    width: 100%;
    padding: 14px 12px;
  }
  .search-container > div:last-child {
    display: none;
  }
  .search-field-container > input {
    width: 100%;
  }
  .search-field-container > div > div {
    margin-right: unset !important;
  }
  .search-box {
    font-size: 16px;
    line-height: 17px;
  }
  .search-toggles {
    display: flex;
    margin-right: auto;
    margin-top: 12px;
    margin-bottom: 32px;
  }
  .search-toggles > label {
    display: flex;
    align-items: center;
    width: max-content;
    color: #737579;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    margin: 0 !important;
    margin-right: 24px !important;
  }
  .search-toggles > label > input {
    margin: 0 !important;
    margin-right: 8px !important;
  }
  .search-btn-mob {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .search-btn-mob > div {
    width: 100%;
  }
  .validation-error-message {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(239 68 68);
    margin-top: 8px;
    font-size: 16px;
  }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .search-btn-mob > div {
    width: 100%;
    max-width: 342px;
  }
}
.select-option-container {
  border-bottom: 1px solid #eaeaea;
  background-color: #dcebef;
  color: '#09101D';
}
.select-option-container:hover {
  border-bottom: 1px solid #eaeaea;
  background-color: #dcebef;
  color: '#09101D';
}

@media screen and (max-width: 1023px) {
  .search-container {
    padding: 10px 16px;
  }
  .search-container > div:last-child {
    padding-left: 12px;
  }
  .search-container > div:last-child > label {
    margin-right: 16px !important;
  }
  .back-button {
    padding-top: 25px;
    padding-left: 0px;
  }
}
