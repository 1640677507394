.landing-page-modal-width {
  width: 430px;
}
.modal-tab-button-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-tab-button-group > div {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.modal-tab-button-group > div > button {
  width: 200px !important;
}
.modal-mt {
  margin-top: 2.5rem; /* 40px */
}
.modal-single-button-width {
  width: 30%;
}
.modal-two-btn-container {
  display: flex;
  gap: 16px;
}

@media screen and (max-width: 767px) {
  .landing-page-modal-width {
    width: 300px;
  }
  .modal-tab-button-group > div > button {
    width: 138px !important;
  }
  .mt-responsive {
    margin-top: 1.5rem; /* 24px */
  }
  .modal-single-button-width {
    width: 100%;
  }
  .modal-responsive-title {
    font-size: 16px;
  }
  .modal-responsive-body {
    font-size: 14px;
  }
}
