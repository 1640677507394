.event-card {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.event-card .header {
  color: #fff;
  padding: 8px;
  height: 76px;
}
.event-card .header a {
  color: #fff;
}
.event-image {
  padding: 15px;
  height: 130px;
}
.event-image img {
  width: 100%;
  max-height: 110px;
}
.event-content {
  position: relative;
  display: block;
  width: 100%;
  padding: 8px;
  border-top: 1px solid #eee;
}

.event-vehicles {
  font-size: 14px;
  font-weight: bold;
}

.header_title {
  width: 200px;
  font-size: 14px;
  font-family: 'Helvetica';
  font-weight: 700;
}

.header_title:hover {
  text-decoration: underline;
}

.header_sub_title {
  width: 200px !important;
  font-size: 14px;
  font-family: 'Helvetica';
  font-weight: 700;
}

.grid_header {
  background: #6492bd;
  color: #ffffff;
  width: 200px;
  font-size: 14px;
  margin-left: -15px;
  padding-left: 10px;
}

.event-card-footer {
  width: 100%;
  float: left;
  position: relative;
  padding-top: 6px;
  padding-bottom: 3px;
  border-top: 1px solid #f6f6f6;
}

.event-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 320px;
  width: 250px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  margin: 8px;
}

@media screen and (max-width: 500px) {
  .event-card-container {
    margin: 5px auto;
  }
}
