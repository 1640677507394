@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,500;1,600;1,700;1,800;1,900&display=swap');


/* background color */
.bg-light {
  background-color: #fafafa;
}
.bg-white {
  background-color: white;
  color: black;
}
.hidden {
  display: none;
}
/* flex */
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-col {
  flex-direction: column;
}
.flex-col-reverse {
  flex-direction: column-reverse;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-evenly {
  justify-content: space-evenly;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
/* font color */
.text-white {
  color: rgba(255, 255, 255, 1);
}
.text-black {
  color: #09101d;
}
.text-red-500 {
  color: rgb(239 68 68);
}

/* text align */
.text-center {
  text-align: center;
}

/* font size */
.text-xs {
  font-size: 12px;
}
.text-sm {
  font-size: 15px;
}
.text-base {
  font-size: 16px;
}
.text-lg {
  font-size: 18px;
}
.text-xl {
  font-size: 19px;
}
.fs-14 {
  font-size: 14px;
}
.fs-20 {
  font-size: 20px;
}
.fs-24 {
  font-size: 24px;
}
.fs-36 {
  font-size: 36px;
}

/* font weight */
.font-hairline {
  font-weight: 100;
}
.font-thin {
  font-weight: 200;
}
.font-light {
  font-weight: 300;
}
.font-normal {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.font-extrabold {
  font-weight: 800;
}
.font-heavy {
  font-weight: 900;
}

/* height */
.h-screen {
  height: 100vh;
}
.max-h-screen {
  max-height: 100vh;
}
.min-h-screen {
  min-height: 100vh;
}
.h-auto {
  height: auto;
}
.h-min {
  height: min-content;
}
.h-max {
  height: max-content;
}
.h-fit {
  height: fit-content;
}
.h-100 {
  height: 100%;
}
.h-1\/2 {
  height: 50%;
}
.h-1\/3 {
  height: 33.333333%;
}
.h-2\/3 {
  height: 66.666667%;
}
.h-1\/4 {
  height: 25%;
}
.h-2\/4 {
  height: 50%;
}
.h-3\/4 {
  height: 75%;
}
.h-1\/5 {
  height: 20%;
}
.h-2\/5 {
  height: 40%;
}
.h-3\/5 {
  height: 60%;
}
.h-4\/5 {
  height: 80%;
}
.h-1\/6 {
  height: 16.666667%;
}
.h-2\/6 {
  height: 33.333333%;
}
.h-3\/6 {
  height: 50%;
}
.h-4\/6 {
  height: 66.666667%;
}
.h-5\/6 {
  height: 83.333333%;
}
.h-0 {
  height: 0px;
}
.h-1 {
  height: 4px;
}
.h-2 {
  height: 8px;
}
.h-3 {
  height: 12px;
}
.h-4 {
  height: 16px;
}
.h-8 {
  height: 32px;
}
.h-11 {
  height: 44px;
}

/* width */
.w-screen {
  width: 100vw;
}
.w-min {
  width: min-content;
}
.w-max {
  width: max-content;
}
.w-fit {
  width: fit-content;
}
.w-100 {
  width: 100%;
}
.w-1\/2 {
  width: 50%;
}
.w-1\/3 {
  width: 33.333333%;
}
.w-2\/3 {
  width: 66.666667%;
}
.w-1\/4 {
  width: 25%;
}
.w-2\/4 {
  width: 50%;
}
.w-3\/4 {
  width: 75%;
}
.w-1\/5 {
  width: 20%;
}
.w-2\/5 {
  width: 40%;
}
.w-3\/5 {
  width: 60%;
}
.w-4\/5 {
  width: 80%;
}
.w-1\/6 {
  width: 16.666667%;
}
.w-2\/6 {
  width: 33.333333%;
}
.w-3\/6 {
  width: 50%;
}
.w-4\/6 {
  width: 66.666667%;
}
.w-5\/6 {
  width: 83.333333%;
}
.w-0 {
  width: 0px;
}
.w-1 {
  width: 4px;
}
.w-2 {
  width: 8px;
}
.w-3 {
  width: 12px;
}
.w-4 {
  width: 16px;
}

/* margin */
.m-0 {
  margin: 0px;
}
.m-1 {
  margin: 4px;
}
.m-2 {
  margin: 8px;
}
.m-3 {
  margin: 12px;
}
.m-4 {
  margin: 16px;
}

/* margin top */
.mt-0 {
  margin-top: 0px;
}
.mt-1 {
  margin-top: 4px;
}
.mt-2 {
  margin-top: 8px;
}
.mt-3 {
  margin-top: 12px;
}
.mt-4 {
  margin-top: 16px;
}
.mt-6 {
  margin-top: 24px;
}
.mt-8 {
  margin-top: 32px;
}
.mt-9 {
  margin-top: 36px;
}
.mt-10 {
  margin-top: 40px;
}
.mt-12 {
  margin-top: 48px;
}
/* margin right */
.mr-0 {
  margin-right: 0px;
}
.mr-1 {
  margin-right: 4px;
}
.mr-2 {
  margin-right: 8px;
}
.mr-3 {
  margin-right: 12px;
}
.mr-4 {
  margin-right: 16px;
}
.mr-6 {
  margin-right: 24px;
}
.mr-8 {
  margin-right: 32px;
}

/* margin bottom */
.mb-0 {
  margin-bottom: 0px;
}
.mb-1 {
  margin-bottom: 4px;
}
.mb-2 {
  margin-bottom: 8px;
}
.mb-3 {
  margin-bottom: 12px;
}
.mb-4 {
  margin-bottom: 16px;
}
.mb-6 {
  margin-bottom: 24px;
}
.mb-8 {
  margin-bottom: 32px;
}
.mb-9 {
  margin-bottom: 36px;
}
.mb-10 {
  margin-bottom: 40px;
}
.mb-12 {
  margin-bottom: 48px;
}
/* margin left */
.ml-0 {
  margin-left: 0px;
}
.ml-1 {
  margin-left: 4px;
}
.ml-2 {
  margin-left: 8px;
}
.ml-3 {
  margin-left: 12px;
}
.ml-4 {
  margin-left: 16px;
}
.ml-4\.5 {
  margin-left: 18px;
}

.ml-6 {
  margin-left: 24px;
}
.ml-8 {
  margin-left: 32px;
}
/* margin horizontal */
.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}
.mx-1 {
  margin-left: 4px;
  margin-right: 4px;
}
.mx-2 {
  margin-left: 8px;
  margin-right: 8px;
}
.mx-3 {
  margin-left: 12px;
  margin-right: 12px;
}
.mx-4 {
  margin-left: 16px;
  margin-right: 16px;
}
.mx-4\.5 {
  margin-left: 18px;
  margin-right: 18px;
}

/* margin vertical */
.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.my-1 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.my-3 {
  margin-top: 12px;
  margin-bottom: 12px;
}
.my-4 {
  margin-top: 16px;
  margin-bottom: 16px;
}

/* padding */
.p-0 {
  padding: 0px !important;
}
.p-1 {
  padding: 4px;
}
.p-2 {
  padding: 8px;
}
.p-3 {
  padding: 12px;
}
.p-4 {
  padding: 16px;
}
.p-8 {
  padding: 32px;
}

/* padding top */
.pt-0 {
  padding-top: 0px;
}
.pt-1 {
  padding-top: 4px;
}
.pt-2 {
  padding-top: 8px;
}
.pt-2\.5 {
  padding-top: 10px;
}
.pt-3 {
  padding-top: 12px;
}
.pt-4 {
  padding-top: 16px;
}
.pt-8 {
  padding-top: 32px;
}
/* margin right */
.pr-0 {
  padding-right: 0px;
}
.pr-1 {
  padding-right: 4px;
}
.pr-2 {
  padding-right: 8px;
}
.pr-3 {
  padding-right: 12px;
}
.pr-4 {
  padding-right: 16px;
}
.pr-8 {
  padding-right: 32px;
}
/* padding bottom */
.pb-0 {
  padding-bottom: 0px;
}
.p-1\/2 {
  padding-bottom: 2px;
}
.pb-1 {
  padding-bottom: 4px;
}
.pb-2 {
  padding-bottom: 8px;
}
.pb-3 {
  padding-bottom: 12px;
}
.pb-4 {
  padding-bottom: 16px;
}

/* padding left */
.pl-0 {
  padding-left: 0px;
}
.pl-1 {
  padding-left: 4px;
}
.pl-2 {
  padding-left: 8px;
}
.pl-3 {
  padding-left: 12px;
}
.pl-4 {
  padding-left: 16px;
}
.pl-6 {
  padding-left: 24px;
}
.pl-8 {
  padding-left: 32px;
}
.pl-10 {
  padding-left: 40px;
}
/* padding horizontal */
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.px-1 {
  padding-left: 4px;
  padding-right: 4px;
}
.px-2 {
  padding-left: 8px;
  padding-right: 8px;
}
.px-3 {
  padding-left: 12px;
  padding-right: 12px;
}
.px-4 {
  padding-left: 16px;
  padding-right: 16px;
}
.px-6 {
  padding-left: 24px;
  padding-right: 24px;
}
.px-8 {
  padding-left: 32px;
  padding-right: 32px;
}

/* padding vertical */
.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.py-1 {
  padding-top: 4px;
  padding-bottom: 4px;
}
.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.py-3 {
  padding-top: 12px;
  padding-bottom: 12px;
}
.py-4 {
  padding-top: 16px;
  padding-bottom: 16px;
}

/* border */
.border-none {
  border-style: none;
}
.border-0 {
  border-width: 0px;
}
.border {
  border-width: 1px;
}
.border-2 {
  border-width: 2px;
}
.border-4 {
  border-width: 4px;
}
.rounded-full {
  border-radius: 9999px;
}
.border-cyan {
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  border-color: #4d9bb0;
}
.border-bottom-dark {
  border-bottom: '1px solid #EAEAEA';
  border-width: 1px;
  border-style: solid;
}
input[name='heavyTruck'] {
  accent-color: #4d9bb0;
}
.relative {
  position: relative;
}
@keyframes loadingFlickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0.25; }
  100% { opacity:1; }
}
@-o-keyframes loadingFlickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.25; }
  100% { opacity:1; }
}
@-moz-keyframes loadingFlickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.25; }
  100% { opacity:1; }
}
@-webkit-keyframes loadingFlickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.25; }
  100% { opacity:1; }
}
.core__scan-loading-flicker {
   -webkit-animation: loadingFlickerAnimation 1.5s infinite;
   -moz-animation: loadingFlickerAnimation 1.5s infinite;
   -o-animation: loadingFlickerAnimation 1.5s infinite;
    animation: loadingFlickerAnimation 1.5s infinite;
}

/* Responsive Styles */
@media screen and (max-width: 1023px) {
  .h-screen {
    height: auto;
  }
}