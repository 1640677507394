::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.core-back-button {
  display: flex;
  cursor: pointer;
}
.core-back-button > span {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
}
.custom-dropdown-menu > ul {
  padding: 0px !important;
  border: 1px solid #dfdfdf !important;
  border-radius: 3px !important;
}
.custom-dropdown-menu > ul > li > a {
  outline: none !important;
  padding: 10px 8px !important;
  border: 1px solid #dfdfdf !important;
  font-size: 12px;
  line-height: 16px;
}
.text-input-field-container {
  width: 100%;
}
.text-input-field-container > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #09101d;
  margin-bottom: 8px;
}
.text-input-field {
  height: 46px;
  width: 100%;
  outline: none;
  border: 1px solid rgba(133, 140, 148, 0.5);
  background-color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding: 16px;
}
.text-area-input-field-container {
  width: 100%;
}
.text-area-input-field-container > div:first-child {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #09101d;
  margin-bottom: 8px;
}
.text-area-input-field {
  resize: none;
  height: 96px;
  width: 100%;
  outline: none;
  border: 1px solid rgba(133, 140, 148, 0.5);
  border-radius: 8px;
  background-color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding: 16px;
}
.select-menu-input-field-container {
  width: 100%;

}
.select-menu-input-field-container > div:first-child {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #09101d;
  margin-bottom: 8px;
}
.file-picker-input-field-container {
  width: 100%;
  min-width: 240px;
  
}
.file-picker-input-field-container > div:first-child {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #09101d;
  margin-bottom: 8px;
 
}
.file-picker-input-field {
  height: 46px;
  width: 100%;
  outline: none;
  border: 1px solid rgba(133, 140, 148, 0.5);
  border-radius: 8px;
  background-color: #ffffff;
  padding-left: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.file-picker-input-field-content > span {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.file-picker-input-field-content > div {
  display: flex;
  align-items: center;

}
.file-picker-input-field-content > div > img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  object-position: center;
}
.file-picker-input-field-content > div > span {
  margin-left: 8px;
  color: #4d9bb0;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-picker-input-field-buttons > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.file-picker-input-field-buttons > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  padding: 0px 12px;
  border-left: 1px solid rgba(133, 140, 148, 0.5);
}
.toast-success {
  color: #05a660;
}
.toast-error {
  color: #fc3f47;
}
.toast-content {
  font-weight: 500;
  font-size: 16px;
}
.toast-content > div > div:first-child {
  display: flex;
  justify-content: center;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
}
.loader-container {
  position: fixed;
  width: inherit;
  height: inherit;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loader-container > div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: black;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: visible;
}
.loader-container svg {
  overflow: visible;
}

@media screen and (max-width: 767px) {
  .toast-content {
    font-size: 16px;
  }
  .text-input-field,
  .text-area-input-field,
  .file-picker-input-field-content > span {
    font-size: 16px;
  }
}

@media screen and (max-width: 1023px) {
  .core-back-button > span {
    font-size: 16px;
    line-height: 21px;
  }
  .text-input-field-container > div:first-child {
    font-size: 16px;
    margin-bottom: 6px;
  }
  .text-area-input-field-container > div:first-child {
    font-size: 14px;
    margin-bottom: 6px;
  }
  .select-menu-input-field-container > div:first-child {
    font-size: 16px;
    margin-bottom: 6px;
  }
  .file-picker-input-field-container > div:first-child {
    font-size: 16px;
    margin-bottom: 6px;
    min-width: 240px;
  }
}
