/* 
   Displayed & Print-only styles for the 
   DataTable.js (+ withTable HoC) component  
 
    Notes: 
    -- To view column widths in real-time:
        - Comment out '@media' open and closing bracket (below)
        - Inspect 'Print' button (browser)
        - Remove 'display: none' from sibling element
    -- '!important' used to override inline styling
*/
@media print {
    .datatable-printable {
        font-size: 14px;
        overflow-x: hidden;

        /* * * Rows * * * */
        .rt-table .rt-thead {
            min-width: 1056px !important;
            width: unset !important;
        }
        .rt-table .rt-tbody {
            min-width: 1056px !important;
            width: unset !important;
        }

        /* * * Columns * * * 
          default='datatable-column__misc' 
        */
        .rt-table {
            /* * 120px * * 
                Image
            */
            .datatable-column__image {
                overflow: hidden !important;
            }

            /* * ~70px * * 
                VIN
            */
            .datatable-column__vin {
                flex: 36 !important;
                min-width: unset !important;
                width: unset !important;
                max-width: unset !important;
            }

            /* * 0px * * 
                CheckBox, Actions (buttons)
            */
            .datatable-column__checkbox,
            .datatable-column__actions {
                display: none !important;
            }

            /* * ~ 22px ~ * *
                Locked  
            */
            .datatable-column__locked {
                flex: 19 !important;
                min-width: unset !important;
                width: unset !important;
                max-width: unset !important;
            }

            /* * ~ 30px ~ * *
                Year
            */
            .datatable-column__year {
                flex: 23 !important;
                min-width: unset !important;
                width: unset !important;
                max-width: unset !important;
            }

            /* * ~ 40px ~ * *
                Numbers ( # of rerun, crgrade)
                Fees
            */
            .datatable-column__rerun,
            .datatable-column__fees,
            .datatable-column__crgrade {
                flex: 29 !important;
                min-width: unset !important;
                width: unset !important;
                max-width: unset !important;
            }

           /* * ~ 50px ~ * * 
              Buyer, # Days in MP,
              Seller, High Bidder, Lot #
            */
            .datatable-column__buyer,
            .datatable-column__daysinmarketplace,
            .datatable-column__highbidder,
            .datatable-column__lotnumber,
            .datatable-column__seller {
                flex: 34 !important;
                min-width: unset !important;
                width: unset !important;
                max-width: unset !important;
            }
            
            /* * ~ 60px ~ * * 
              Vehicle Data:
                Engine, Transmission,
                Ext color, Model, Make
              Other:
                Title, Misc
              Prices:
                Amount, Reserve, (B|S)Offer,
                BuyNow, HighBid, MyHighBid,
                Seller Wants
            */
            .datatable-column__event,
            .datatable-column__misc,
            .datatable-column__model,
            .datatable-column__make,
            .datatable-column__prices,
            .datatable-column__status,
            .datatable-column__title ,
            .datatable-column__vehicledata {
                flex: 38 !important;
                min-width: unset !important;
                width: unset !important;
                max-width: unset !important;
            }

            /* * ~ 70px ~ * * 
                QR, Odometer,
                New / Used, Retail / Wholesale,
                Stock #
            */
            .datatable-column__qrcode,
            .datatable-column__mileage,
            .datatable-column__neworused,
            .datatable-column__retailorwholesale,
            .datatable-column__stocknumber {
                flex: 40 !important;
                min-width: unset !important;
                width: unset !important;
                max-width: unset !important;
            }

            /* * * ~ 90px ~ * * 
              Dates:
                Date Added, Sale Date,
                Sold Date, Purchase Date,
                Offer Date, Bid Date, S-Date,
              Type
            */
            .datatable-column__dates,
            .datatable-column__type {
                flex: 45 !important;
                min-width: unset !important;
                width: unset !important;
                max-width: unset !important;
            }
        }
    }

    .ReactTable .rt-thead .rt-th {
        appearance: none;
        box-shadow: none;
        background: unset;
        text-overflow: ellipsis !important;
    }
}