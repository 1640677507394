/*Vehicle Grid*/
.vehicle-card {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin: 8px;
  display: block;
  float: left;
  width: 250px;
}

@media screen and (max-width: 585px) {
  .vehicle-card {
    margin: 5px auto;
    width: 100%;
  }
}
  
.vehicle-card .header {
  /* border-top: 3px solid #6492bd; */
  float: left;
  width: 100%;
  color: #6492bd;
  padding: 6px;
  height: 58px;
  border-bottom: 1px solid #eee;
}
.vehicle-card .header a {
  color: #6492bd;
  font-weight: bold;
  font-size: 15px;
}
.vehicle-card .sub-heading {
  font-size: 12px;
}

.view-360 {
  position: absolute;
  padding: 4px 6px;
  color: #fff;
  bottom: 5px;
  left: 5px;
  z-index: 1;
  border-radius: 2px;
  font-size: 15px;
  opacity: 0.9;
  border-radius: 20px;
  text-align: center;
  min-width: 20px;
  white-space: pre;
}

.cr-button {
  position: absolute;
  padding: 4px 6px;
  color: #fff;
  bottom: 5px;
  left: 5px;
  z-index: 1;
  border-radius: 2px;
  font-size: 15px;
  opacity: 0.9;
  text-align: center;
}

.lot-number {
  position: absolute;
  padding: 4px 6px;
  color: #fff;
  top: 5px;
  left: 5px;
  z-index: 1;
  border-radius: 2px;
  font-size: 15px;
  opacity: 0.9;
  text-align: center;
}

.vehicle-card-price {
  position: absolute;
  padding: 2px 6px;
  color: #fff;
  background-color: #83be97;
  bottom: 5px;
  right: 5px;
  z-index: 1;
  font-size: 14px;
  opacity: 0.95;
  border-radius: 2px;
  text-align: center;
}

.vehicle-card-bid {
  position: absolute;
  padding: 4px 6px;
  color: #fff;
  background-color: #00b295;
  right: 5px;
  z-index: 1;
  font-size: 14px;
  opacity: 0.95;
  border-radius: 2px;
  text-align: center;
}

.seller-name {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 230px;
  height: 26px;
  padding: 4px 6px;
  color: #fff;
  z-index: 1;
  background-color: #000;
  opacity: 0.5;
}
.vehicle-card-content {
  position: relative;
  float: left;
  display: block;
  width: 100%;
  padding: 8px;
}
.vehicle-card-content hr {
  margin-top: 5px;
  margin-bottom: 5px;
}
.vehicle-miles {
  font-size: 12px;
}
.vehicle-buy-now {
  font-size: 14px;
  width: 50%;
  color: #00b295;
  float: right;
  text-align: right;
}
.vehicle-card-footer {
  /* width: 100%;
  float: left;
  position: relative;
  padding-top: 6px;
  padding-bottom: 3px; */
  border-top: 1px solid #eee;
}
ul.action-buttons {
  display: block;
  list-style: none;
  margin: 5px 0px;
  padding: 0px;
  float: right;
}
ul.action-buttons li.action-btn {
  float: left;
  text-align: center;
}
.vehicle-card ul.action-buttons li {
  float: left;
  text-align: center;
  margin-right: 7px;
}
.action-buttons a {
  font-size: 11px;
  border-radius: 3px;
  padding: 4px 5px;
  display: block;
  font-weight: 500;
  background-color: #787878;
  color: #fff;
}
.action-buttons a:hover {
  background-color: #a5adcb;
  color: #fff;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.action-buttons a.buy {
  background-color: #83be97;
}
.action-buttons a.buy:hover {
  background-color: #8ed3a6;
}

.action-buttons a.offer {
  background-color: #83be97;
}
.action-buttons a.offer:hover {
  background-color: #8ed3a6;
}

.action-buttons a span {
  margin-left: 4px;
}
/*Vehicle List Items*/
.vehicle-list-item {
  position: relative;
  display: block;
  /* float: left; */
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  font-size: 14px;
}

.vehicle-list-view_item__buttons {
  width: 150px;
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-left: 1px solid #f1f1f1;
}

.vehicle-list-view_item__container {
  position: relative;
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 6px;
  min-height: 220px;
  height: 220px;
  margin-bottom: 1px;
  width: 95%;
}

/* ItemsPage.js - RecentlyViewed, Bidding On, etc. - List view */
.run-list-container > .vehicle-list-view_item__container {
  margin-bottom: 20px;
}

.vehicle-list-view_item__image {
  position: relative;
  object-fit: contain;
  width: 100%;
  height: 100%;
  max-height: 220px;
}

.vehicle-list-view_item__image_dynamic {
  position: relative;
  object-fit: contain;
  width: 100%;
  height: 100%;
  max-height: 220px;
}

.vehicle-list-view_item__image-wrapper {
  width: 290px; /* to keep 4:3 */
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.vehicle-list-view_item__image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: #000;
  object-fit: contain;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  height: 100%;
  width: 100%;
}

.vehicle-list-view_item__inline {
  width: 150px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-left: 1px solid #f1f1f1;
}

.vehicle-list-view_item__vehicle_info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}

.vehicle-list-view_item__vehicle_info .list-view__info-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}



/* Vehicle List View - 1350px */
@media screen and (max-width: 1350px) {
  .vehicle-list-view_item__container {
    height: auto;
    max-height: 350px;
  }
  .vehicle-list-view_item__image {
    max-height: 350px;
    max-width: 250px;
  }
  .vehicle-list-view_item__image-container {
    height: auto;
  }
  .vehicle-list-view_item__image-wrapper {
    flex-shrink: 0;
    max-height: 260px;
    width: 180px;
  }
}
/* Vehicle List View - Tablet */
@media screen and (max-width: 1100px) {
  .vehicle-card-bid {
    right: 0;
  }
  .vehicle-list-view_item__buttons {
    width: 125px;
  }
  
  .vehicle-list-view_item__container {
    align-items: flex-start;
    border: 1px solid #ccc;
    border-radius: 6px;
    display: flex;
    justify-content: space-evenly;
    min-height: 230px;
    height: unset;
    max-height: 550px;
    margin: 0 auto;
  }
  
  .vehicle-list-view_item__image {
    max-height: 240px;
  }
  
  .vehicle-list-view_item__image_dynamic {
    position: relative;
    object-fit: contain;
    width: 100%;
    height: 100%;
    /* max-height: 220px; */
  }
  
  .vehicle-list-view_item__image-wrapper {
    /* width: 290px; */ /* to keep 4:3 */
    border-bottom: solid #f1f1f1;
    cursor: pointer;
    flex-shrink: 0;
    max-height: 260px;
    width: 180px;
  }
  
  .vehicle-list-view_item__image-container {
    border-bottom-left-radius: 0px;
    /* max-width: 260px; */
    object-fit: contain;
    height: unset;
    width: 100%;
  }
  
  .vehicle-list-view_item__inline {
    width: 125px;
  }
  
  .vehicle-list-view_item__vehicle_info {
    padding: 2px 5px;
    border-bottom: 0;
  }

  .vehicle-feature-list {
    font-size: small;
  }
}

/* Vehicle List View * 
   --- Mobile --- 
*/
@media screen and (max-width: 650px) {
  /* Items Page */
  .run-list-container > .vehicle-list-view_item__container {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .vehicle-list-view_item__qrcode {
    display: none;
  }
  .vehicle-list-view_item__buttons {
    border-left: 0;
    gap: 10px;
    width: 12rem;
  }
  
  .vehicle-list-view_item__container {
    align-items: flex-start;
    border: 1px solid #ccc;
    flex-direction: column;
    align-items: center;
    height: unset;
    max-height: 750px;
    min-height: 250px;
  }
  
  .vehicle-list-view_item__image {
    max-height: 230px;
  }
  
  .vehicle-list-view_item__image-container {
    border-top-left-radius: 0;
  }

  /* .vehicle-list-view_item__image_dynamic {
    max-height: 220px;
  } */
  
  .vehicle-list-view_item__image-wrapper {
    border-bottom: unset;
    /* flex-shrink: 0; */
    height: 150px;
    margin: 0px auto;
    overflow: hidden;
    position: relative;
    width: 180px;
  }

  .vehicle-list-view_item__inline {
    width: 11rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 0;
  }
  .vehicle-list-view_item__inline > form .form-group {
    font-size: 16px;
  }
  
  .vehicle-list-view_item__vehicle_info {
    border-bottom: solid #f1f1f1;
  }

  .vehicle-list-view_item__vehicle_info .list-view__info-label {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .vehicle-feature-list {
    font-size: small;
  }
}
/* Vehicle List View 
    -- Mobile -- <= 375w 
*/
@media screen and (max-width: 375px) {
  .vehicle-list-view_item__container {
    max-height: 750px;
    min-height: 275px;
  }
}

/*.vehicle-list-item-image{
  position:relative;
  float:left;
  width:220px;
  height:165px;
  overflow: hidden;
  background-color: #000;
  cursor: pointer;
}*/

/* .vehicle-list-item-image { */
/* position: relative; */
/* height: 165px; */
/* overflow: hidden; */
/* background-color: #000; */
/* cursor: pointer; */
/* } */
/*.vehicle-list-item-content{
  position:relative;
  margin-left:220px;
  border-left:3px solid #787878;
  height:165px;
}*/
.vehicle-list-item-content {
  position: relative;
  /* border-left: 3px solid #787878; */
  /* height: 165px; */
}
.vehicle-list-item-content .miles-color {
  margin-right: 10px;
}
.vehicle-list-item-image img {
  /* width: 220px;
  height: 100%; */
}
.vehicle-list-item-content .header a {
  font-size: 18px;
}
/*.vehicle-list-info{
  padding-top:5px;
  padding-left:8px;
  width:100%;
  margin-right:-160px;
  padding-right:165px;
  float:left;
}*/
.vehicle-list-info {
  padding-top: 5px;
}
.vehicle-list-item .distance {
  font-size: 12px;
  margin-right: 10px;
}
/*ul.list-action-buttons{
  display:block;
  list-style: none;
  float:right;
  height:100%;
  width:160px;
  padding:10px;
  margin-bottom:0px;
  border-left:1px solid #f1f1f1;
}*/
ul.list-action-buttons {
  list-style: none;
  padding: 10px;
  margin-bottom: 0px;
  border-left: 1px solid #f1f1f1;
}
/*ul.list-action-buttons li.action-btn{
  float:left;
  text-align: center;
  width:100%;
  margin-bottom:8px;
}*/
ul.list-action-buttons li.action-btn {
  text-align: right;
  margin-bottom: 16px;
  width: 100%;
}

ul.list-action-buttons li.action-btn a {
  width: 100%;
  display: block;
  text-align: center;
}
/*.list-action-buttons a{
  font-size:14px;
  background-color: #787878;
  border-radius: 3px;
  padding:5px 8px;
  width:100%;
  display:block;
  color:#fff;
  box-shadow:0 1px 5px rgba(0, 0, 0, 0.15);
}*/
.list-action-buttons a {
  font-size: 14px;
  background-color: #787878;
  border-radius: 3px;
  padding: 5px 8px;
  color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  width: 100%;
}
.list-action-buttons a.buy {
  background-color: #83be97;
}
.list-action-buttons a.buy:hover {
  background-color: #8ed3a6;
}
.list-action-buttons a:hover {
  background-color: #a5adcb;
  color: #fff;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.list-action-buttons a span.fa {
  font-size: 14px;
  margin-right: 4px;
}
.vehicle-list-item-content .header {
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 5px;
  margin-bottom: 5px;
  width: 100%;
}
/*.vehicle-list-content-body{
  float:left;
  width:100%;
}*/
.vehicle-list-history-logos img {
  height: 23px;
  float: left;
  margin-right: 10px;
  margin-top: 2px;
  cursor: pointer;
}
/*.vehicle-list-barcode img{
  height:44px;
  float:left;
  margin-right:5px;
}*/
/*.vehicle-list-barcode img{
  height:44px;
  margin-right:5px;
}*/
.vehicle-list-barcode {
  width: 44px;
  height: 44px;
}
.vehicle-list-barcode img {
  height: 100%;
  width: 100%;
  margin-right: 5px;
}
.barcode-label {
  font-size: 11px;
  display: block;
  margin-top: -5px;
  margin-bottom: 0px;
}
.slick-list {
}
.watchlist-btn {
  font-size: 1.6em;
}
.watchlist-btn.added {
}
.watchlist-btn.default {
}
.watchlist-btn {
  unicode-bidi: bidi-override;
  direction: rtl;
}
.watchlist-btn > span.fa-star {
  display: inline-block;
}
.watchlist-btn > span.fa-star {
  color: #ccc;
}
.watchlist-btn > span.fa-star:before {
  content: '\f006';
  color: #fff;
}
.watchlist-btn span.fa-star:hover:before,
.watchlist-btn span.fa-star:hover ~ span.fa-star:before {
  content: '\f005';
  color: #e3cf7a;
}
.main-vehicle-list-item {
  border: 1px solid #ccc;
}
.main-vehicle-list-item figure img {
  width: 100%;
  height: auto;
}
.main-vehicle-list-item .box-title {
  margin: 0;
}
.vehicle-feature-list .no-padding {
  padding: 0 !important;
}
.vehicle-list-info-divider {
  margin: 5px 0;
}

@media all and (max-width: 992px) {
  .vehicle-list-item-content {
    padding-top: 5px;
    border-left: none;
    border-top: 3px solid #787878;
  }
}

@media print {
  .vehicle-list-item-content {
    margin-top: 80px;
  }
}

.printFrame {
  width: 100%;
  position: absolute;
  left: 0;
  top: 250px;
  height: 250px;
  display: none;
}

.image-gallery-fullscreen-button::before,
.image-gallery-play-button::before,
.image-gallery-left-nav::before,
.image-gallery-right-nav::before {
  color: rgb(92, 190, 127) !important;
}

.image-gallery .fullscreen {
  max-width: 100% !important;
  margin-top: 20vh !important;
}

.bulklot-badge {
  position: absolute;
  z-index: 1;
  width: fit-content;
  display: block;
  background: lightblue;
  padding: 3px 5px;
  font-size: 12px;
  font-weight: bold;
  color: #000;
  text-transform: uppercase;
  text-align: center;
  top: 5px;
  left: 5px;
  border-radius: 2px;
}

.vehicle-card-bulk-shadow {
  box-shadow:
    /* The top layer shadow */ 1px -1px 1px rgba(0, 0, 0, 0.15),
    /* The second layer */ 10px -10px 0 -5px #eee,
    /* The second layer shadow */ 10px -10px 1px -4px rgba(0, 0, 0, 0.15),
    /* The third layer */ 20px -20px 0 -10px #eee,
    /* The third layer shadow */ 20px -20px 1px -9px rgba(0, 0, 0, 0.15);
}
