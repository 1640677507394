.form-container.review-container {
  margin: 16px 0;
  vertical-align: middle;
}
.review-card-title {
  width: 100%;
  padding: 14px;
  background: #4d9bb0;
  color: #fff;
  border-radius: 4px 4px 0px 0px;
  font-size: 16px;
}
.review-card-body {
  display: flex;
  flex-direction: column;
  border: solid 1px #d1d5db;
  border-top: none;
  border-radius: 0 0 8px 8px;
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.review-card-row {
  display: flex;
  gap: 24px;
  margin: 0 24px;
}
.review-card-row > div {
  display: flex;
  flex-grow: 0;
  flex-basis: 48%;
  justify-content: space-between;
  border-bottom: 1px solid #dfdfdf;
  padding: 16px 0;
}
.review-card-row-full > div > div {
  color: #696969
}
.review-card-row-full > div > div:first-child {
  color: #263347;
}

.review-card-row-full {
  display: flex;
  /* gap: 24px; */
  margin: 0 24px;
}
.review-card-row-full > div {
  display: flex;
  flex-grow: 0;
  
  flex-basis: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #dfdfdf;
  padding: 16px 0;
}
.review-card-row-full > div > div {
  color: #696969
}
.review-card-row-full > div > div:first-child {
  color: #263347;
}

.review-options {
  margin-bottom: 32px;
}
.print-options-toggle {
  transform: scale(1.5);
  width: 4.2rem !important;
}
.print-options-toggle label:before {
  border: 1px solid #98989e !important;
  background: #fff !important;
}
.print-options-toggle label:after {
  background: #98989e !important;
}
.print-options-toggle.ui.toggle.checkbox input:checked ~ label:before,
.print-options-toggle.checked label:after {
  background: #4d9bb0 !important;
  border: 1px solid #e0e0e0 !important;
}
.print-options-toggle.checked label:after {
  border: 1px solid #fff !important;
}
.core-toggle {
  transform: scale(1.5);
  width: 4.2rem !important;
}
.core-toggle.ui.toggle.checkbox input:focus:checked~.box:before,
.core-toggle.ui.toggle.checkbox input:focus:checked~label:before {
    background-color: teal !important
}
.core-toggle.ui.toggle.checkbox .box:before, core-toggle.ui.toggle.checkbox label:before {
    background-color: grey;
}
.core-toggle.ui.toggle.checkbox input:checked ~ .box:before,
.core-toggle.ui.toggle.checkbox input:checked ~ label:before {
  background-color: #4d9bb0 !important;
}
.image-upload-success {
  color: #0ca737;
  font-size: 16px;
  text-align: right;
}
.image-upload-fail {
  color: #fc3f47;
  font-size: 16px;
  flex-basis: 50%;
  text-align: right;
}
.image-upload-error {
  color: #fc3f47;
}
.Toastify__toast-container--top-center {
  width: 50%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0;
  margin: 0;
}
.review-toast {
  border-radius: 12px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  align-items: center;
}
.review-container-width {
  width: 75%;
}

@media screen and (max-width: 767px) {
  .form-container.review-container {
    margin: 0;
    width: 100vw;
    height: 100vh;
  }
  .review-card-title {
    font-size: 16px;
  }
  .review-card-row {
    display: block;
    font-size: 14px;
    margin: 0 8px;
  }
  .review-container.review-container-width {
    width: 95%;
  }
  .review-options {
    margin-bottom: 16px;
  }
  .review-options-label {
    font-size: 14px;
    font-weight: 600;
  }
  .print-options-toggle {
    transform: scale(1.3);
    width: 4rem !important;
  }
  .image-upload-success,
  .image-upload-fail {
    font-size: 14px;
  }
  .image-upload-error {
    font-size: 12px;
  }
  .Toastify__toast-container--top-center {
    width: 100vw;
  }
}

@media screen and (max-width: 1023px) {
  .review-card-row {
    display: block;
    font-size: 12px;
    margin: 0 8px;
  }
  .review-container-width {
    width: 70%;
  }
}

